import React from "react"
import { Link } from "react-router-dom"

import { useSelector } from "react-redux"
import { map } from "lodash"
import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import useCloudinary from "constants/UseCloudinaryImage"

const Banner5 = () => {
  const { banner, loading } = useSelector(state => ({
    banner: state.Banner.banners,
    loading: state.Banner.loading,
  }))

  const subBanners = banner?.subBanners

  return (
    <>
      {loading ? (
        <>
          <CustomizedLoader
            count={3}
            mobileCount={1}
            style={{ height: "250px" }}
            innerClass="mx-1"
          />
        </>
      ) : (
        <>
          {map(subBanners, (ban, key) => (
            <div className="col-lg-4 col-md-6  m-auto" key={key}>
              <Link to={`/custom/collection/${ban?.link?.replace(/ /g, "-")}`}>
                <div
                  className="banner-img wow animate__animated md:mb-2 animate__fadeInUp w-100"
                  style={{
                    height: "265px",
                  }}
                  data-wow-delay="0"
                >
                  <img
                    src={useCloudinary(ban?.images[0]?.url, "q_auto:eco")}
                    alt=""
                    className="h-100 w-100 object-cover"
                    style={{ objectFit: "cover" }}
                  />
                  <div className="banner-text">
                    {/* <h4>
                Everyday Fresh & <br />
                Clean with Our
                <br />
                Products
              </h4> */}
                    {/* <Link to="/products">
                <span className="btn btn-xs text-white">
                Shop Now <i className="fi-rs-arrow-small-right"></i>
                </span>
              </Link> */}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </>
      )}
      {/* <div className="col-lg-4 d-md-none d-lg-flex">
        <div
          className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp"
          data-wow-delay=".4s"
        >
          <img src={""} alt="" />
          <div className="banner-text">
            <h4>
              The best Organic <br />
              Products Online
            </h4>
            <Link to="/products">
              <span className="btn btn-xs text-white">
                Shop Now <i className="fi-rs-arrow-small-right"></i>
              </span>
            </Link>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Banner5
