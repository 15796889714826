import React from "react"
import useLangSwitch from "constants/LanguageSwicth"
import useCartActions from "constants/useCartAction"
import usePrice from "constants/UsePrice"
import PropTypes from "prop-types"

import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai"
import { BsExclamationCircle } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import useCloudinary from "constants/UseCloudinaryImage"

function CheckoutItem({ item }) {
  const product = item?.product
  const dispatch = useDispatch()

  const { cartItems, loading } = useSelector(state => ({
    cartItems: state.Cart.cartItems,
    loading: state.Cart.addOrRemoveLoad,
  }))

  const { currencyCode, returnText } = usePrice(product)

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const increaseQuantity = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "add",
    }
    useCartActions(options, dispatch, cartItems)
  }

  const decreaseQuantity = item => {
    if (item?.quantity <= 1) {
      return
    } else {
      const options = {
        product: item?.product?._id,
        varient: item?.varient,
        operation: "substract",
      }
      useCartActions(options, dispatch)
    }
  }

  const currentVarient = item?.product?.varients?.filter(
    i => i._id === item?.varient
  )[0]

  const handleStockText = () => {
    if (item?.isOutOfStock === true) {
      if (currentVarient?.stock <= 0) {
        return "Out of Stock"
      } else if (item?.quantity > currentVarient?.stock) {
        return "Only " + currentVarient?.stock + " left"
      }
    } else return ""
  }

  const removeSpace = language?.name?.replace(/ /g, "-")

  return (
    <>
      <tr className="checkout-items-border">
        <>
          <td className="image product-thumbnail ">
            <Link to={`/products/${removeSpace}`}>
              <img
                src={useCloudinary(product.images[0]?.url, "h_400,w_400")}
                alt="#"
              />
            </Link>
          </td>
          <td>
            <h6 className="w-160 mb-5">
              <Link to={`/products/${removeSpace}`}>
                {language?.name}
                <br />
                <span className="white-space-nowrap">
                  ({item?.varientValue}&nbsp;
                  {item?.varientUnit})
                </span>
              </Link>
            </h6>{" "}
          </td>
          <td>
            <h6 className="text-muted pl-20 pr-20">
              <span className="text-brand white-space-nowrap">
                {currencyCode} {item?.price}{" "}
                <span className="text-gray">x {item?.quantity}</span>
              </span>
              <div className="d-flex  m-0 py-2">
                {loading ? (
                  <span className="line_load bg-lightgreen"></span>
                ) : (
                  <>
                    <AiOutlineMinusCircle
                      className="text-brand cursor-pointer hover-up"
                      size={"1.3rem"}
                      onClick={() => decreaseQuantity(item)}
                    />
                    <AiOutlinePlusCircle
                      className="mx-1 text-brand cursor-pointer hover-up"
                      size={"1.3rem"}
                      onClick={() => increaseQuantity(item)}
                    />
                  </>
                )}
              </div>
            </h6>
          </td>
          <td>
            <h4 className="text-brand white-space-nowrap">
              {currencyCode} {item.quantity * item?.price}
            </h4>
          </td>
          {handleStockText() && (
            <tr
              className="text-danger d-flex align-items-center"
              style={{ height: "40px" }}
            >
              <td>
                <BsExclamationCircle className="text-danger mx-2" />{" "}
                {handleStockText()}
              </td>
            </tr>
          )}
          {item?.isReturnViable && (
            <tr
              className="d-flex align-items-center"
              style={{ height: "40px" }}
            >
              <td>
                <BsExclamationCircle className="text-green mx-2" /> {returnText}
              </td>
            </tr>
          )}
        </>
      </tr>
    </>
  )
}
export default CheckoutItem

CheckoutItem.propTypes = {
  item: PropTypes.any,
}
