import Layout from "components/layout/Layout"
import React from "react"

import OrderContent from "./OrderContent"

function OrderSuccess() {
  return (
    <Layout parent="Home" sub="Checkout" subChild="Success">
      <div className="invoice invoice-content invoice-5">
        <div className="p-2 m-auto" style={{ maxWidth: "1200px" }}>
          <OrderContent isNavbar={true} isShopMore={true} />
        </div>
      </div>
    </Layout>
  )
}

export default OrderSuccess
