import React from "react"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

const LimitSelect = ({ pathname }) => {
  const location = useLocation()
  const history = useHistory()

  const [limit, setLimit] = useState(20)

  useEffect(() => {
    history.push({
      pathname: pathname ? pathname : "/shop-list",
      query: {
        ...location.query,
        limit: limit,
      },
    })
  }, [limit])

  const seleceOption = e => {
    setLimit(e.target.value)
  }

  return (
    <>
      <div className="sort-by-product-wrap">
        <div className="sort-by">
          <span>
            <i className="fi-rs-apps-sort"></i>
            Show :
          </span>
        </div>
        <div className="sort-by-dropdown-wrap custom-select">
          <select onChange={e => seleceOption(e)}>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  products: state?.products?.items,
})

const mapDidpatchToProps = {
  // updateProductFilters,
}

export default connect(mapStateToProps, mapDidpatchToProps)(LimitSelect)

LimitSelect.propTypes = {
  pathname: PropTypes.any,
}
