import React, { Fragment, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { FaSpinner } from "react-icons/fa"
import { setDefualtAddress, UpdateUserAddress } from "store/actions"
import { map } from "lodash"

const UserAddressEditPopup = ({ isShow, setIsShow, data }) => {
  const dispatch = useDispatch()

  const [shippingInfo, setShippingInfo] = useState({})

  const { addressLoad, shippingInputs, settingLoad } = useSelector(state => ({
    addressLoad: state.Cart.addressLoad,
    shippingInputs: state.SiteSettings.siteSettings.shippingInputs,
    settingLoad: state.SiteSettings.loading,
  }))

  const handleClose = () => {
    setIsShow({ ...isShow, status: false })
  }

  const handleChangeInput = e => {
    setShippingInfo({
      shippingInfo: {
        ...shippingInfo.shippingInfo,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleChangeCheckbox = e => {
    setShippingInfo({
      shippingInfo: { ...shippingInfo },
      isDefault: e.target.checked,
    })

    if (shippingInfo) {
      const input = {
        shippingInfo: { ...shippingInfo },
        isDefault: e.target.checked,
      }
      dispatch(setDefualtAddress(data?._id, input))
    }
  }

  useEffect(() => {
    setShippingInfo(data)
  }, [data])

  const handleSubmitAddress = e => {
    e.preventDefault()
    dispatch(UpdateUserAddress(shippingInfo, data?._id))
    setIsShow({ ...isShow, status: false })
  }

  function HandleInputType(input) {
    switch (input) {
      case "contactNumber":
      case "pincode":
        return "number"
      case "email":
        return "email"
      default:
        return "text"
    }
  }

  function HandleInputValue(input) {
    switch (input) {
      case "name":
        return shippingInfo?.shippingInfo?.name
      case "addressLine1":
        return shippingInfo?.shippingInfo?.addressLine1
      case "area":
        return shippingInfo?.shippingInfo?.area
      case "contactNumber":
        return shippingInfo?.shippingInfo?.contactNumber
      case "district":
        return shippingInfo?.shippingInfo?.district
      case "city":
        return shippingInfo?.shippingInfo?.city
      case "country":
        return shippingInfo?.shippingInfo?.country
      case "othercountry":
        return shippingInfo?.shippingInfo?.othercountry
      case "landmark":
        return shippingInfo?.shippingInfo?.landmark
      case "pincode":
        return shippingInfo?.shippingInfo?.pincode
      case "firstName":
        return shippingInfo?.shippingInfo?.firstName
      case "middleName":
        return shippingInfo?.shippingInfo?.middleName
      case "lastName":
        return shippingInfo?.shippingInfo?.lastName
      case "email":
        return shippingInfo?.shippingInfo?.email
      case "address":
        return shippingInfo?.shippingInfo?.address
      case "addressLine2":
        return shippingInfo?.shippingInfo?.addressLine2
      case "state":
        return shippingInfo?.shippingInfo?.state
      case "street":
        return shippingInfo?.shippingInfo?.street
      default:
        return ""
    }
  }

  return (
    <>
      <div
        className={
          !isShow
            ? "modal fade custom-modal d-none"
            : "modal fade custom-modal  show d-block"
        }
      >
        <div className="modal-dialog">
          <div className="modal-content px-0 p-0">
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <div className="modal-body p-1 sm:p-4">
              <div className="deal custom-border align-items-center flex-col d-flex justify-content-center sm:p-0">
                <div
                  className="deal-top d-flex justify-between align-items-center h-50 pb-0 "
                  style={{
                    padding: "6px 20px 18px",
                  }}
                >
                  <div>
                    <h5 className="text-brand">Edit Address </h5>
                  </div>
                </div>

                <div className="deal-content pt-4 px-4 sm:pt-4 pb-10">
                  {" "}
                  <form onSubmit={handleSubmitAddress}>
                    <div className="row">
                      {map(shippingInputs, (input, key) => (
                        <Fragment key={key}>
                          {input?.name === "address" ? (
                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                              <textarea
                                name={input?.name}
                                placeholder={input?.name}
                                rows="4"
                                cols="50"
                                style={{ minHeight: "10px" }}
                                value={shippingInfo?.shippingInfo?.address}
                                onChange={e => handleChangeInput(e)}
                              ></textarea>
                            </div>
                          ) : (
                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                              <input
                                type={HandleInputType(input?.name)}
                                name={input?.name}
                                placeholder={input?.name}
                                value={HandleInputValue(input?.name)}
                                onChange={e => handleChangeInput(e)}
                              />
                            </div>
                          )}
                        </Fragment>
                      ))}
                      <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <div className="d-flex align-item-center">
                          <input
                            checked={shippingInfo?.isDefault}
                            type="checkbox"
                            name="isDefault"
                            className="mx-1"
                            onChange={e => handleChangeCheckbox(e)}
                            style={{ width: "20px", height: "20px" }}
                          />
                          <label> Set this address by default</label>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-fill-out btn-block btn-md w-25 sm:w-50 ml-auto mx-3 mt-30"
                      >
                        Save Address
                        {addressLoad && <FaSpinner className="spinner mx-1" />}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          !isShow ? "modal-backdrop fade d-none" : "modal-backdrop fade show"
        }
      ></div>
    </>
  )
}

export default UserAddressEditPopup

UserAddressEditPopup.propTypes = {
  isShow: PropTypes.bool,
  setIsShow: PropTypes.func,
  data: PropTypes.any,
}
