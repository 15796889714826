import CategorySlider from "components/sliders/Category"
import React from "react"

function CategorySliderContainer() {
  return (
    <section className="popular-categories section-padding">
      <div className="container wow animate__fadeIn animate__animated">
        <div className="section-title">
          <div className="title">
            <h3 className="gp_head">Categories</h3>
          </div>
        </div>
        <div className="carausel-10-columns-cover position-relative">
          <div className="carausel-10-columns" id="carausel-10-columns">
            <CategorySlider />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CategorySliderContainer
