import React, { useEffect } from "react"
import ProductDetails from "../../components/ecommerce/productCards/ProductDetails"
import Layout from "../../components/layout/Layout"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getProductDetail, getRelatedProducts } from "store/actions"
import useLangSwitch from "constants/LanguageSwicth"
import ProductLoader from "components/Common/loaders/ProductLoader"

const ProductSingle = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const { productDetail, loading } = useSelector(state => ({
    productDetail: state.Products.productDetail.product,
    loading: state.Products.loading,
  }))

  const removeArrow = params?.name?.replace(/-/g, " ")

  useEffect(() => {
    dispatch(getProductDetail(removeArrow))
    dispatch(getRelatedProducts(removeArrow))
  }, [dispatch, removeArrow])

  const language = useLangSwitch(
    productDetail?.primaryLang,
    productDetail?.secondaryLang
  )

  return (
    <Layout parent="Home" sub="Shop" subChild={language?.name}>
      <div className="container">
        {loading ? (
          <ProductLoader height="400px" />
        ) : (
          productDetail?._id && <ProductDetails product={productDetail} />
        )}
      </div>
    </Layout>
  )
}
export default ProductSingle

ProductSingle.propTypes = {
  product: PropTypes.any,
}
