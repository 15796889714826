import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import TableLoader from "components/Common/loaders/TableLoader"
import { useDispatch, useSelector } from "react-redux"
import { updateUserInfo } from "store/actions"
import { FaSpinner } from "react-icons/fa"

function UserInfoChange({ activeIndex }) {
  const dispatch = useDispatch()

  const { user, loading } = useSelector(state => ({
    user: state.Login.user,
    loading: state.Login.loading,
  }))

  const [input, setInput] = useState()

  const handleChangeInput = e => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    })
  }

  const handleUpdateUserDetails = e => {
    e.preventDefault()
    dispatch(updateUserInfo(input))
  }
  console.log(input)

  useEffect(() => {
    setInput({
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
    })
  }, [user])

  return (
    <div
      className={
        activeIndex === 5 ? "tab-pane fade active show" : "tab-pane fade "
      }
    >
      <div className="card md:mt-50">
        <div className="card-header">
          <h5>Account Details</h5>
        </div>

        <div className="card-body">
          <form name="enq" onSubmit={handleUpdateUserDetails}>
            <div className="row">
              <div className="form-group col-md-12">
                <label>Full Name</label>
                <input
                  className="form-control"
                  name="name"
                  type="text"
                  value={input?.name}
                  onChange={e => handleChangeInput(e)}
                />
              </div>

              <div className="form-group col-md-12">
                <label>Mobile Number</label>
                <input
                  required={true}
                  className="form-control"
                  name="phone"
                  type="text"
                  value={input?.phone}
                  disabled
                />
              </div>
              {/* <div className="form-group col-md-12">
                  <label>
                    Email Address <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    onChange={e => handleChangeInput(e)}
                  />
                </div> */}

              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-fill-out submit font-weight-bold"
                  name="submit"
                  value="Submit"
                >
                  Save Change
                  {loading && <FaSpinner className="mx-2 spinner" />}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UserInfoChange

UserInfoChange.propTypes = {
  activeIndex: PropTypes.any,
}
