import { connect, useDispatch, useSelector } from "react-redux"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import PropTypes from "prop-types"
import { getCollections } from "store/actions"
import RotatingGallery from "components/Common/loaders/RotatingGallery"
import useCloudinary from "constants/UseCloudinaryImage"

const CollectionProduct = ({ className }) => {
  const dispatch = useDispatch()

  const { collections, loading, currentStore, defaultStore } = useSelector(
    state => ({
      collections: state.LayoutGroup.collections,
      loading: state.LayoutGroup.loading,
      currentStore: state.NearbyStore.store,
      defaultStore: state.SiteSettings.defaultStore,
    })
  )

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  useEffect(() => {
    if (currentStoreId) {
      dispatch(getCollections(currentStoreId))
    }
  }, [dispatch, currentStoreId])

  return (
    <>
      {loading ? (
        <RotatingGallery />
      ) : (
        <div className="row d-flex">
          {map(collections, (coll, key) => (
            <Link
              to={{
                pathname: `/custom/collection/${coll?.primaryLang?.title?.replace(
                  / /g,
                  "-"
                )}`,
                state: "collection",
              }}
              className="col-6"
            >
              <article
                className={"text-center hover-up mb-30 animated"}
                key={key}
              >
                <img
                  src={useCloudinary(coll?.bannerImages[0]?.url, "w_1200")}
                  className="fit-cover rounded-sm"
                  height="100%"
                  width={"100%"}
                />
              </article>
            </Link>
          ))}{" "}
        </div>
      )}
    </>
  )
}

export default connect(null, {})(CollectionProduct)

CollectionProduct.propTypes = {
  className: PropTypes.string,
}
