import ListVertical from "components/ecommerce/productCards/ListVertical"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "constants/LanguageSwicth"
import { map } from "lodash"
import { useBanner } from "./useBannerData"
import useCloudinary from "constants/UseCloudinaryImage"

function ProductG2({ groupDetail }) {
  const history = useHistory()
  const language = useLangSwitch(
    groupDetail?.primaryLang,
    groupDetail?.secondaryLang
  )

  const product = groupDetail?.products

  const handleBannerData = useBanner(
    // groupDetail?.singleCollection,
    // groupDetail?.singleCategory,
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12">
          <h3 className="mb-4 gp_head">{language?.title}</h3>
          <div className="single-hero-slider single-animation-wrap p-5 w-100 mb-4 pb-4 border-radius-15  background_image">
            <img
              src={useCloudinary(
                groupDetail?.images[0]?.url
                  ? groupDetail?.images[0]?.url
                  : handleBannerData[0]?.bannerImages[0]?.url ?? "",
                "w_1500"
              )}
              className="border-radius-10"
              onClick={() =>
                history.push(
                  `/custom/${
                    groupDetail?.type === "single collection"
                      ? "collection"
                      : "category"
                  }/${handleBannerData[0]?.primaryLang?.title?.replace(
                    / /g,
                    "-"
                  )}`
                )
              }
            />
            <div className="titles">
              <p>{handleBannerData[0]?.primaryLang?.title}</p>
              <h1 className="display-5 mb-40">
                {handleBannerData[0]?.primaryLang?.description}
              </h1>
            </div>
            {/* <div className="slider-content">
              <h1 className="display-5 mb-40">
                {handleBannerData?.primaryLang?.description}
              </h1>
              <Link
                to={`/custom/collection/${handleBannerData?.primaryLang?.title?.replace(
                  / /g,
                  "-"
                )}`}
              >
                <span className="btn btn-lg text-white d-flex w-fit align-items-center justify-center ">
                  Explore More <i className="fi-rs-arrow-right mx-3"></i>
                </span>
              </Link>
            </div> */}
          </div>
          <div className="row">
            {map(product, (aProduct, key) => (
              <div
                className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 mt-3 wow animate__animated animate__fadeInUp "
                data-wow-delay="0"
                key={key}
              >
                <ListVertical product={aProduct} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductG2

ProductG2.propTypes = {
  groupDetail: PropTypes.object,
}
