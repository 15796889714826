import React from "react"
import CollectionProduct from "components/ecommerce/filter/CollectionProduct"
import PropTypes from "prop-types"
import Layout from "components/layout/Layout"

function AllColllection() {
  return (
    <Layout parent="Home" sub="Collections" subChild="">
      <section className="mt-10 mb-50">
        <section className="mt-20 mb-30">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className={"col-12 primary-sidebar sticky-sidebar"}>
                <div className="sidebar-widget product-sidebar mb-20 p-30 bg-grey border-radius-10 custom_category_label p-0 pt-3">
                  <CollectionProduct />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export default AllColllection

AllColllection.propTypes = {
  sideBarShow: PropTypes.any,
  setSideBarShow: PropTypes.any,
}
