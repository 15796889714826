import React, { useEffect, useState } from "react"
import "assets/css/app.css"
import Preloader from "components/elements/Preloader"
import { LogoFull } from "assets/imgs/dealsToHome"
import { FaApple, FaGooglePlay } from "react-icons/fa"
import appStore from "assets/imgs/theme/app-store.jpg"
import googlePlay from "assets/imgs/theme/google-play.jpg"
import background from "assets/imgs/dealsToHome/banner.png"
import { isAndroid, isIOS } from "react-device-detect"

function AppMobile() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const handleRedirectToAppStore = () => {
    window.location.replace("https://apps.apple.com/in/app/deals2home/id1671999277")

    setTimeout(() => {
      window.location.replace(
        "https://apps.apple.com/in/app/deals2home/id1671999277"
      )
    }, 10000)
  }

  const handleRedirectToGooglePlay = () => {
    const url =
      "https://play.google.com/store/apps/details?id=com.osperb.deals_to_home"

    window.location.replace(url)
  }

  useEffect(() => {
    if (isAndroid) {
      const url =
        "https://play.google.com/store/apps/details?id=com.osperb.deals_to_home"
    } 

    else if (isIOS) {
      window.location.replace("https://apps.apple.com/in/app/deals2home/id1671999277")

      setTimeout(() => {
        window.location.replace(
          "https://apps.apple.com/in/app/deals2home/id1671999277"
        )
      }, 10000)
    }
  }, [])

  if (loading) return <Preloader />
  return (
    <div className="app_screen">
      <div className="app_wrapp">
        <div>
          <div className="app_screen_top">
            <p>WELCOME TO</p>
            <img src={LogoFull} />
          </div>
          <div className="app_screen_text">
            <p>
              Start you&#39;r daily shopping with Deals To Home, For the best
              shopping experience, use our desktop web app or download our iOS
              or Android Mobile app
            </p>
          </div>
          <div className="app_screen_middle">
            <div className="downloadCard" onClick={handleRedirectToGooglePlay}>
              <img src={googlePlay} />
            </div>

            <div
              className="downloadCard two"
              onClick={handleRedirectToAppStore}
            >
              <img src={appStore} />
            </div>
              
          </div>
        </div>
        <div className="app_screen_footer">
          <img src={background} className="main_bg" />
        </div>
      </div>
    </div>
  )
}

export default AppMobile

{/* <FaApple />
              <div>
                <span>Get on the</span>
                <h6>App Store</h6>
              </div> */}

                 {/* <FaGooglePlay />
              <div>
                <span>Download on the</span>
                <h6>Google Play</h6>
              </div> */}