import CategoryTab from "components/ecommerce/categoryTab"
import React from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { useBanner } from "./useBannerData"
import useCloudinary from "constants/UseCloudinaryImage"

function ProductG1({ groupDetail }) {
  const history = useHistory()
  const product = groupDetail?.products

  const handleBannerData = useBanner(
    // groupDetail?.singleCollection,
    // groupDetail?.singleCategory,
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12">
          <div className="section-title mb-4 mt-4 style-2 wow animate__animated animate__fadeIn ">
            <h3 className="gp_head">{groupDetail?.primaryLang?.title}</h3>
          </div>
          <div className="single-hero-slider single-animation-wrap p-5 w-100 mb-4 pb-4 border-radius-15  background_image">
            <img
              src={useCloudinary(
                groupDetail?.images[0]?.url
                  ? groupDetail?.images[0]?.url
                  : handleBannerData[0]?.bannerImages[0]?.url ?? "",
                "w_1500"
              )}
              className="border-radius-10"
              onClick={() =>
                history.push(
                  `/custom/${
                    groupDetail?.type === "single collection"
                      ? "collection"
                      : "category"
                  }/${handleBannerData[0]?.primaryLang?.title?.replace(
                    / /g,
                    "-"
                  )}`
                )
              }
            />
            <div className="titles">
              {" "}
              <p>{handleBannerData[0]?.primaryLang?.title}</p>
              <h1 className="display-5 mb-40">
                {handleBannerData[0]?.primaryLang?.description}
              </h1>
            </div>
          </div>

          <CategoryTab products={product} noHead={true} />
        </div>
      </div>
    </section>
  )
}

export default ProductG1

ProductG1.propTypes = {
  groupDetail: PropTypes.object,
}
