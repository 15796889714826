import React from "react"
import PropTypes from "prop-types"
import ProductCardOne from "./productCards/ProductCardOne"
import { useHistory } from "react-router-dom"

function CategoryTab({ products, handleBannerData, noHead, bannerImg, type }) {
  const history = useHistory()
  return (
    <>
      <div className="tab-content wow fadeIn animated">
        <div className={"tab-pane fade show active"}>
          <div className="product-grid-4 row">
            {!noHead && (
              <div className="col-lg-7 col-md-8 col-12 col-sm-6">
                <div className="single-hero-slider single-animation-wrap p-5 w-100 ml-auto mb-4 pb-4 border-radius-15 h:300px background_image">
                  <img
                    src={
                      bannerImg
                        ? bannerImg
                        : handleBannerData?.bannerImages[0]?.url
                    }
                    className="border-radius-10 h-100 w-100 fit-cover"
                    onClick={() =>
                      history.push(
                        `/custom/${
                          type === "single collection"
                            ? "collection"
                            : "category"
                        }/${handleBannerData?.primaryLang?.title?.replace(
                          / /g,
                          "-"
                        )}`
                      )
                    }
                  />
                  {/* <p>{handleBannerData?.primaryLang?.title}</p>

                <div className="slider-content">
                  <h1 className="display-5 mb-40">
                    {handleBannerData?.primaryLang?.description}
                  </h1>
                  <Link to={`/custom/collection/${handleBannerData?.primaryLang?.title}`}>
                    <span className="btn btn-lg text-white d-flex w-fit align-items-center justify-center ">
                      Explore More <i className="fi-rs-arrow-right mx-3"></i>
                    </span>
                  </Link>
                </div> */}
                </div>
              </div>
            )}
            {products?.map((product, i) => (
              <div className="col-lg-1-5 col-md-4 col-12 col-sm-6" key={i}>
                <ProductCardOne product={product} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
export default CategoryTab

CategoryTab.propTypes = {
  products: PropTypes.array,
  handleBannerData: PropTypes.any,
  noHead: PropTypes.bool,
  bannerImg: PropTypes.any,
  type: PropTypes.any,
}
