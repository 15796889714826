import React, { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import PropTypes from "prop-types"

import iconHeart from "assets/imgs/theme/icons/icon-heart.svg"
import iconCart from "assets/imgs/theme/icons/icon-cart.svg"
import iconUser from "assets/imgs/theme/icons/icon-user.svg"
import iconHot from "assets/imgs/theme/icons/icon-hot.svg"
import quickList from "assets/imgs/theme/icons/noun-shopping-cart.png"
import Search from "components/ecommerce/Search"
import {
  getCategories,
  getCollections,
  getFavouriteItems,
  getQuicklist,
  getUserCart,
  logoutUser,
  showLocationPopupAction,
  showLoginPopupAction,
} from "store/actions"
import location from "assets/imgs/login/location.jpg"
import CategoryProduct from "components/ecommerce/filter/CategoryProduct"
import { map } from "lodash"
import CartItemList from "components/elements/cartItemComp2"
import usePrice from "constants/UsePrice"
import EmptyCartSvg from "components/Common/emptyCart"
import useLangSwitch from "constants/LanguageSwicth"
import { useOutsideAlerter } from "constants/useOutSideClick"
import { useWindowSize } from "react-use"
import useCloudinary from "constants/UseCloudinaryImage"

const Header = ({ totalCartItems, toggleClick }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cateRef = useRef(null)
  const { width } = useWindowSize()

  const {
    userPlace,
    cartItems,
    userCart,
    company,
    specialMessages,
    favourites,
    quickPurchase,
    isDefaultStore,
    currentStore,
    defaultStore,
    collections,
    user,
  } = useSelector(state => ({
    user: state.Login.user,
    userPlace: state.NearbyStore.userPlace,
    cartItems: state.Cart.cartItems,
    userCart: state.Cart.userCart,
    company: state?.SiteSettings.siteSettings?.company,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    favourites: state.Cart.favourites,
    quickPurchase: state.Cart.quickPurchase,
    isDefaultStore: state.NearbyStore.store.isDefault,
    currentStore: state.NearbyStore.store,
    defaultStore: state.SiteSettings.defaultStore,
    collections: state.LayoutGroup.collections,
  }))

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  const [isToggled, setToggled] = useState(false)
  const [scroll, setScroll] = useState(false)

  const [scrollY, setScrollY] = useState()

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(cateRef, setOutSide)

  useEffect(() => {
    const onScroll = e => {
      setScrollY(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    if (scrollY >= 100) {
      setScroll(true)
    } else {
      setScroll(false)
    }

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollY])

  const handleToggle = () => setToggled(!isToggled)

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  const handleLocationPopup = () => dispatch(showLocationPopupAction())

  const token = localStorage.getItem("token_grocery")

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getCategories("", "all"))
    dispatch(getFavouriteItems(1, 10))
    dispatch(getQuicklist(1, 10))
  }, [dispatch])

  useEffect(() => {
    if (currentStoreId) {
      dispatch(getCollections(currentStoreId))
    }
  }, [dispatch, currentStoreId])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleLoginFun = path => {
    if (token) {
      history.push(path)
    } else {
      handleLoginPopup()
    }
  }

  useEffect(() => {
    if (outSide === true) {
      setToggled(false)
      setOutSide(false)
    }
  }, [outSide])

  const isHotDeals = collections?.filter(i =>
    i?.primaryLang?.title === "Hot Deals" ? true : false
  )

  return (
    <>
      <header className="header-area header-style-1 header-height-2">
        {language?.productOffer && (
          <div className="mobile-promotion">
            {language?.productOffer && <span>{language?.productOffer}</span>}
          </div>
        )}
        <div className="header-top header-top-ptb-1 d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-4">
                <div className="header-info">
                  <ul>
                    {/* <li>
                      <Link to="/page-about">
                        <span>About Us</span>
                      </Link>
                    </li> */}
                    <li>
                      {token ? (
                        <Link
                          to={{
                            pathname: "/user-account",
                            state: { index: 4 },
                          }}
                        >
                          <span>My Account</span>
                        </Link>
                      ) : (
                        <span
                          onClick={handleLoginPopup}
                          className="cursor-pointer"
                        >
                          <i className="fi fi-rs-user mr-10"></i>
                          Login
                        </span>
                      )}
                    </li>
                    <li>
                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/wishlist")}
                      >
                        <span>Wish List</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: "/user-account",
                          state: { index: 2 },
                        }}
                      >
                        <span>Order Tracking</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/categroies"}>
                        <span>Categories</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4">
                <div className="text-center">
                  <div id="news-flash" className="d-inline-block">
                    <ul>
                      {language?.bannerOffer && (
                        <li>
                          {language?.bannerOffer}&nbsp;
                          <Link to="/shop-grid-right">
                            <span> View details</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="header-info header-info-right">
                  <ul>
                    <li>
                      Need help ?&nbsp; Call Us&nbsp;:{" "}
                      <strong className="text-brand">
                        &nbsp;{company?.contactDetails?.mobile}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
          <div className="container">
            <div className="header-wrap">
              <div className="logo logo-width-2">
                <Link to="/">
                  <img
                    src={useCloudinary(company?.logo[0]?.url, "w_300")}
                    height="60px"
                    width={"80px"}
                    alt="logo"
                    className="fit-contain mx-4"
                  />
                </Link>
              </div>
              <div className="header-right mx-4">
                <div className="search-style-2 position-relative max-w:600px">
                  <Search />
                </div>
                <div className="header-action-right">
                  <div className="header-action-2">
                    <div className="header-action-icon-2 ">
                      <Link
                        onClick={() => handleLoginFun("/quick-purchase")}
                        to={"#!"}
                      >
                        <img
                          className="svgInject object-cover"
                          alt="quick"
                          height={"25px"}
                          width={"25px"}
                          src={quickList}
                        />
                        {quickPurchase?.total >= 1 ? (
                          <span className="pro-count blue ">
                            {quickPurchase?.total}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                      <Link
                        onClick={() => handleLoginFun("/quick-purchase")}
                        to={"#!"}
                      >
                        <span className="lable">Quick Purchase</span>
                      </Link>
                    </div>

                    <div className="header-action-icon-2 ">
                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/wishlist")}
                      >
                        <span>
                          <img
                            className="svgInject"
                            alt="deals2home"
                            src={iconHeart}
                          />
                          {favourites?.total >= 1 ? (
                            <span className="pro-count blue">
                              {favourites?.total}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </Link>
                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/wishlist")}
                      >
                        <span className="lable">Wish List</span>
                      </Link>
                    </div>

                    <div className="header-action-icon-2">
                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/cart")}
                        className={isDefaultStore ? "cursor-notAllowed" : ""}
                      >
                        <div className="mini-cart-icon">
                          <img alt="deals2home" src={iconCart} />
                          {cartItems?.length >= 1 ? (
                            <span className="pro-count blue">
                              {cartItems?.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Link>

                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/cart")}
                        className={isDefaultStore ? "cursor-notAllowed" : ""}
                      >
                        <span className="lable">Cart</span>
                      </Link>

                      <div className="cart-dropdown-wrap cart-dropdown-hm2">
                        {cartItems?.length >= 1 ? (
                          <>
                            <ul>
                              {map(cartItems, (item, key) => (
                                <Fragment key={key}>
                                  <CartItemList item={item} />
                                </Fragment>
                              ))}
                            </ul>
                            <div className="shopping-cart-footer">
                              <div className="shopping-cart-total">
                                <h4>
                                  Total
                                  <span>
                                    {currencyCode} {userCart?.totalPrice}
                                  </span>
                                </h4>
                              </div>
                              <div className="shopping-cart-button">
                                <Link
                                  onClick={() => handleLoginFun("/cart")}
                                  to={"#!"}
                                  className={
                                    isDefaultStore ? "cursor-notAllowed" : ""
                                  }
                                >
                                  View cart
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyCartSvg style={{ height: "170px" }} />
                        )}
                      </div>
                    </div>

                    <div className="header-action-icon-2">
                      {token ? (
                        <>
                          <Link to="/user-account">
                            <span>
                              <img
                                className="svgInject"
                                alt="user"
                                src={iconUser}
                              />
                            </span>
                          </Link>
                          <Link to="/user-account">
                            <span>
                              {user?.name ? (
                                <span style={{ textTransform: "capitalize" }}>
                                  {user?.name?.split(" ")[0]}
                                </span>
                              ) : (
                                <span className="lable ml-0">Account</span>
                              )}
                            </span>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to="#!" onClick={handleLoginPopup}>
                            <span>
                              <img
                                className="svgInject"
                                alt="user"
                                src={iconUser}
                              />
                            </span>
                          </Link>
                          <Link to="#!" onClick={handleLoginPopup}>
                            <span>
                              <span className="lable ml-0">Login</span>
                            </span>
                          </Link>
                        </>
                      )}
                      {token ? (
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                          <ul>
                            <li>
                              <Link to="/user-account">
                                <span>
                                  <i className="fi fi-rs-user mr-10"></i>
                                  My Account
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: "/user-account",
                                  state: { index: 2 },
                                }}
                              >
                                <span>
                                  <i className="fi fi-rs-location-alt mr-10"></i>
                                  Order Tracking
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: "/user-account",
                                  state: { index: 4 },
                                }}
                              >
                                <span>
                                  <i className="fi fi-rs-label mr-10"></i>
                                  My Address
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"#!"}
                                onClick={() => handleLoginFun("/wishlist")}
                              >
                                <span>
                                  <i className="fi fi-rs-heart mr-10"></i>
                                  My wish list
                                </span>
                              </Link>
                            </li>

                            <li>
                              <Link to="#!" onClick={handleLogout}>
                                <span>
                                  <i className="fi fi-rs-sign-out mr-10"></i>
                                  Sign out
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //mobile */}
        <div
          className={
            scroll
              ? "header-bottom header-bottom-bg-color sticky-bar stick"
              : "header-bottom header-bottom-bg-color sticky-bar"
          }
        >
          <div className="container">
            <div
              className="header-wrap header-space-between position-relative"
              // style={{ padding: "20px 0" }}
            >
              <div
                className="logo logo-width d-block d-lg-none"
                style={{ padding: width > 768 && "15px 0" }}
              >
                <Link to="/">
                  <img
                    src={company?.logo[0]?.url}
                    alt="logo"
                    height="20px"
                    width={"60px"}
                  />
                </Link>
              </div>
              <div className="header-nav d-none d-lg-flex">
                <div className="main-categori-wrap d-none d-lg-block">
                  <a
                    className="categories-button-active"
                    onClick={handleToggle}
                  >
                    <span className="fi-rs-apps"></span>
                    <span className="et">Browse</span> All Categories
                    <i className="fi-rs-angle-down"></i>
                  </a>

                  <div
                    className={
                      isToggled
                        ? "categories-dropdown-wrap categories-dropdown-active-large font-heading open "
                        : "categories-dropdown-wrap categories-dropdown-active-large font-heading"
                    }
                    style={{ width: "766px" }}
                  >
                    <div
                      className="d-flex categori-dropdown-inner"
                      ref={cateRef}
                    >
                      <CategoryProduct
                        className="list_cat"
                        showAll={true}
                        showCount={false}
                        handleToggle={handleToggle}
                      />
                    </div>

                    {/* <Link to={"/custom/category"} className="more_categories">
                      <span className="icon"></span>{" "}
                      <span className="heading-sm-1">Show more...</span>
                    </Link> */}
                  </div>
                </div>
                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block  font-heading">
                  <nav>
                    <ul>
                      <li className="hot-deals">
                        <img src={iconHot} alt="hot deals" />
                        <Link
                          to={
                            isHotDeals === true
                              ? `/custom/collection/Hot-Deals`
                              : "/"
                          }
                        >
                          Hot Deals
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "/shop-list",
                            query: {
                              prod: "all",
                            },
                          }}
                        >
                          All Products
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/categroies">Categories</Link>
                      </li> */}
                      <li>
                        <Link to="/collections">Collections</Link>
                      </li>
                      {/* 
                      <li>
                        <Link to="/about">About</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </div>

              {scroll === false ? (
                <div
                  className="main-categori-wrap d-none d-lg-block custom_location"
                  style={{ marginRight: 0 }}
                >
                  <a
                    href="#!"
                    onClick={handleLocationPopup}
                    className="categories-button-active "
                  >
                    <img src={location} alt="hotline" />
                    <div>
                      <p className="m-0 p-0 text-size-14 text-gray line-h-16px">
                        Delivery location
                      </p>
                      {userPlace?.streetName && (
                        <p className="m-0 p-0 text-size-14 text-black line-h-16px">
                          {userPlace?.streetName
                            ? userPlace?.streetName?.slice(0, 30)
                            : "Pic Delivery Location"}
                        </p>
                      )}
                    </div>
                  </a>
                </div>
              ) : (
                <>
                  {width > 992 && (
                    <div className="header-action-icon-2 d-flex align-items-center">
                      <Link
                        className="mx-2 position-relative"
                        to={"#!"}
                        onClick={() => handleLoginFun("/wishlist")}
                      >
                        <span>
                          <img
                            className="svgInject"
                            alt="deals2home"
                            src={iconHeart}
                          />
                          {favourites?.total >= 1 ? (
                            <span
                              className="pro-count text-white navIcon2 bg-darkGreen px-1"
                              style={{ borderRadius: "50%" }}
                            >
                              {favourites?.total}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </Link>

                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/cart")}
                        className={`position-relative ${
                          isDefaultStore ? "mx-2 cursor-notAllowed" : "mx-2"
                        }`}
                      >
                        <div className="mini-cart-icon">
                          <img alt="deals2home" src={iconCart} />
                          {cartItems?.length >= 1 ? (
                            <span
                              className="pro-count text-white navIcon2 bg-darkGreen px-1"
                              style={{ borderRadius: "50%" }}
                            >
                              {cartItems?.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Link>
                      {token && (
                        <Link to="/user-account">
                          <span>
                            <img
                              className="svgInject"
                              alt="user"
                              src={iconUser}
                            />
                          </span>
                        </Link>
                      )}
                    </div>
                  )}
                </>
              )}
              <div className="header-action-icon-2 d-block d-lg-none">
                &nbsp;
              </div>

              <div className="header-action-right d-block d-lg-none">
                <div className="header-action-2">
                  {token ? (
                    <Link to="/user-account">
                      <span>
                        {user?.name ? (
                          <span style={{ textTransform: "capitalize" }}>
                            {user?.name?.split(" ")[0]}
                          </span>
                        ) : (
                          <span className="lable ml-0">Account</span>
                        )}
                      </span>
                    </Link>
                  ) : (
                    <div className="header-action-icon-2">
                      <Link className="m-0" to="#!" onClick={handleLoginPopup}>
                        <img className="svgInject" alt="user" src={iconUser} />
                      </Link>
                    </div>
                  )}

                  <div className="header-action-icon-2">
                    <Link
                      to={"#!"}
                      className="m-0"
                      onClick={() => handleLoginFun("/quick-purchase")}
                    >
                      <span className="mini-cart-icon">
                        <img
                          className="svgInject"
                          alt="quick"
                          height={"25px"}
                          width={"25px"}
                          src={quickList}
                        />

                        {quickPurchase?.total >= 1 && (
                          <span className="pro-count blue">
                            {quickPurchase?.total}
                          </span>
                        )}
                      </span>
                    </Link>
                  </div>

                  <div className="header-action-icon-2">
                    <Link
                      to={"#!"}
                      onClick={() => handleLoginFun("/cart")}
                      className={isDefaultStore ? "cursor-notAllowed" : ""}
                    >
                      <span className="mini-cart-icon">
                        <img alt="deals2home" src={iconCart} />
                        {totalCartItems?.length >= 1 && (
                          <span className="pro-count white">
                            {totalCartItems}
                          </span>
                        )}
                      </span>
                    </Link>
                  </div>

                  <div
                    className="header-action-icon d-block d-lg-none "
                    style={{ padding: "0 10px" }}
                  >
                    <div
                      className="burger-icon burger-icon-white"
                      onClick={toggleClick}
                    >
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className="main-categori-wrap mt-4 mb-2 m-auto p-1 display-sm-block mx-1 custom_location rounded-md"
        style={{ marginRight: 0 }}
      >
        <a
          href="#!"
          onClick={handleLocationPopup}
          className="categories-button-active rounded "
        >
          <img src={location} alt="hotline" />
          <div>
            <p className="m-0 p-0 text-size-14 text-gray line-h-16px">
              Delivery location
            </p>
            {userPlace?.streetName && (
              <p className="m-0 p-0 text-size-14 text-black line-h-16px">
                {userPlace?.streetName
                  ? userPlace?.streetName
                  : "Pic Delivery Location"}
              </p>
            )}
          </div>
        </a>
      </div>
    </>
  )
}

export default Header

Header.propTypes = {
  totalCartItems: PropTypes.any,
  toggleClick: PropTypes.any,
  totalWishlistItems: PropTypes.any,
}
