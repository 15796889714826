import React, { Fragment, useEffect } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import SwiperCore, { Navigation, Thumbs } from "swiper"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Swiper, SwiperSlide } from "swiper/react"
import category1 from "assets/imgs/theme/icons/category-1.svg"

import { useDispatch, useSelector } from "react-redux"
import { map } from "lodash"
import { useLangSwitchquery } from "constants/LanguageSwicth"
import CheckboxFilter from "./CheckboxFilter"
import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import RotatingGallery from "components/Common/loaders/RotatingGallery"
import { getCategories } from "store/actions"

SwiperCore.use([Navigation, Thumbs])

function MainCatAndSubCatList() {
  const language = useLangSwitchquery()
  const history = useHistory()
  const dispatch = useDispatch()

  const { mainCategories, loading } = useSelector(state => ({
    mainCategories: state.Classification.mainCategories,
    loading: state.Classification.loading,
  }))

  const handleClick = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")

    e.preventDefault()
    history.push({
      pathname: `/custom/category/${removeSpace}`,
    })
  }

  useEffect(() => {
    dispatch(getCategories(1, "main"))
  }, [dispatch])

  return (
    <div className="px-2">
      {loading ? (
        <>
          <RotatingGallery />
        </>
      ) : (
        <>
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={10}
            freeMode={true}
            slidesPerView={"auto"}
            navigation={true}
            className="mySwiper2 myNewArrow"
          >
            {map(mainCategories, (mainCate, key) => (
              <SwiperSlide
                key={key}
                onClick={e => handleClick(e, mainCate.primaryLang?.name)}
                style={{ width: "fit-content" }}
              >
                <ul>
                  <li
                    className="px-3 py-2"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #c5eada",
                    }}
                  >
                    <Link to={"#"} className={"cat-item text"}>
                      {language === "Primary"
                        ? mainCate.primaryLang?.name
                        : mainCate.secondaryLang?.name}
                    </Link>
                  </li>
                </ul>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  )
}

export default MainCatAndSubCatList
