import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import { getUserAddress, logoutUser } from "store/actions"
import DashBoard from "./DashBoard"
import MyAccount from "./myAccount/MyAccount"
import UserInfoChange from "./myAccount/UserInfoChange"
import OrderDetails from "./order/orderDetails"
import UserOrders from "./order/Orders"

function Account() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [activeIndex, setActiveIndex] = useState(1)
  const [orderId, setOrderId] = useState()

  const handleOnClick = (index, orderId) => {
    setActiveIndex(index)
    setOrderId(orderId)
  }

  useEffect(() => {
    const index = location?.state?.index
    if (index) {
      setActiveIndex(index)
    } else {
      // setActiveIndex(1)
    }
  }, [location?.state?.index])

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  useEffect(() => {
    dispatch(getUserAddress())
  }, [])

  useEffect(() => {
    window.scroll(0, 0)
  }, [activeIndex])

  return (
    <>
      <Layout parent="Home" sub="Pages" subChild="Account">
        <div className="page-content pt-60 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dashboard-menu">
                      <ul
                        className="nav flex-column sm:flex-row sm:justify-content-between"
                        role="tablist"
                      >
                        <li className="nav-item sm:w-100">
                          <a
                            className={
                              activeIndex === 1 ? "nav-link active" : "nav-link"
                            }
                            onClick={() => handleOnClick(1)}
                          >
                            <i className="fi-rs-settings-sliders mr-10 "></i>
                            Dashboard
                          </a>
                        </li>
                        <li className="nav-item sm:w-49">
                          <a
                            className={
                              activeIndex === 2 ? "nav-link active" : "nav-link"
                            }
                            onClick={() => handleOnClick(2)}
                          >
                            <i className="fi-rs-shopping-bag mr-10 sm:d-none"></i>
                            Orders
                          </a>
                        </li>

                        <li
                          className="nav-item sm:w-49 h-fit"
                          style={{ height: "57px" }}
                        >
                          <a
                            className={
                              activeIndex === 4 ? "nav-link active" : "nav-link"
                            }
                            onClick={() => handleOnClick(4)}
                          >
                            <i className="fi-rs-marker mr-10 sm:d-none"></i>My
                            Address
                          </a>
                        </li>
                        <li className="nav-item sm:w-49 white-space-nowrap h-fit">
                          <a
                            className={
                              activeIndex === 5 ? "nav-link active" : "nav-link"
                            }
                            onClick={() => handleOnClick(5)}
                          >
                            <i className="fi-rs-user mr-10 sm:d-none"></i>
                            Account details
                          </a>
                        </li>
                        <li className="nav-item sm:w-49 h-fit">
                          <Link
                            to={"#!"}
                            className={"nav-link"}
                            onClick={handleLogout}
                          >
                            <i className="fi-rs-sign-out mr-10 sm:d-none "></i>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content account dashboard-content pl-50 sm:p-0">
                      <DashBoard
                        activeIndex={activeIndex}
                        handleOnClick={handleOnClick}
                      />
                      <UserOrders
                        activeIndex={activeIndex}
                        handleOnClick={handleOnClick}
                      />
                      <MyAccount activeIndex={activeIndex} />
                      <UserInfoChange activeIndex={activeIndex} />
                      {orderId && (
                        <OrderDetails
                          activeIndex={activeIndex}
                          orderId={orderId}
                          handleOnClick={handleOnClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Account
