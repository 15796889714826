import React from "react"
import PropTypes from "prop-types"

const CheckboxFilter = ({ data, query, handleCheckBox }) => {
  return (
    <>
      <div style={{ height: "22px" }}>
        <input
          type="checkbox"
          className="form-check-input cursor-pointer"
          name={data.primaryLang?.name}
          value={data.primaryLang?.name}
          checked={query?.cat?.replace(/-/g, " ") === data.primaryLang?.name}
          id={data?._id}
          onChange={e => handleCheckBox(e, data.primaryLang?.name)}
        />
      </div>
    </>
  )
}

export default CheckboxFilter

CheckboxFilter.propTypes = {
  data: PropTypes.any,
  query: PropTypes.any,
  handleCheckBox: PropTypes.func,
}
