import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"

import SwiperCore, { Navigation, Thumbs } from "swiper"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Swiper, SwiperSlide } from "swiper/react"

import { useSelector } from "react-redux"
import { map } from "lodash"
import { useLangSwitchquery } from "constants/LanguageSwicth"
import RotatingGallery from "components/Common/loaders/RotatingGallery"

SwiperCore.use([Navigation, Thumbs])

function SubCategoryList() {
  const location = useLocation()
  const history = useHistory()

  const { subCategories, loading } = useSelector(state => ({
    subCategories: state.Classification.subCategories,
    loading: state.Classification.loading,
  }))

  const [subCats, setSubCats] = useState([])

  const language = useLangSwitchquery()
  const query = location?.query

  const handleCheckBox = (e, SubCategory) => {
    e.preventDefault()
    history.push({
      pathname: location?.pathname ?? "/shop-list",
      query: {
        ...location.query,
        cat: SubCategory?.replace(/ /g, "-"),
        coll: "",
        isProduct: false,
        subCat: true,
      },
    })
  }

  useEffect(() => {
    if (query?.cat && !query?.subCat) {
      const filteredSubCat = subCategories?.filter(
        sub =>
          sub?.mainCategory?.primaryLang?.name ===
          query?.cat?.replace(/-/g, " ")
      )
      setSubCats(filteredSubCat)
    }
  }, [query])

  return (
    <>
      {loading ? (
        <>
          <RotatingGallery />
        </>
      ) : (
        <>
          {query?.prod === "all" ? (
            ""
          ) : (
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              freeMode={true}
              slidesPerView={"auto"}
              navigation={true}
              className="mySwiper2 myNewArrow"
            >
              {map(subCats, (subCat, key) => (
                <SwiperSlide
                  key={key}
                  onClick={e => handleCheckBox(e, subCat.primaryLang?.name)}
                  style={{ width: "fit-content" }}
                >
                  <ul>
                    <li
                      className={`${
                        query?.cat?.replace(/-/g, " ") ===
                        subCat?.primaryLang?.name
                          ? "active_tag"
                          : ""
                      } `}
                      style={{
                        borderRadius: "25px",
                        border: "1px solid #c5eada",
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/shop-list",
                          query: { ...location?.query, cat: "" },
                        }}
                        className={"cat-item text"}
                      >
                        {language === "Primary"
                          ? subCat.primaryLang?.name
                          : subCat.secondaryLang?.name}
                      </Link>
                    </li>
                  </ul>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </>
      )}
    </>
  )
}

export default SubCategoryList
