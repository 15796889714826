import React, { useEffect, useRef } from "react"
import download from "assets/imgs/theme/icons/icon-download.svg"
import { GiShoppingBag } from "react-icons/gi"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"
import { useSelector } from "react-redux"
import { map } from "lodash"
import { useLangSwitchquery } from "constants/LanguageSwicth"
import usePrice from "constants/UsePrice"
import { Link, useHistory } from "react-router-dom"
import { exportComponentAsJPEG } from "react-component-export-image"
import { LogoFull } from "assets/imgs/dealsToHome"

function OrderContent({ isNavbar, isFooter, isAccount }) {
  const history = useHistory()
  const { width } = useWindowSize()
  const componentRef = useRef()
  const language = useLangSwitchquery()

  const { order, shipping } = useSelector(state => ({
    order: state.Cart.order,
    shipping: state.SiteSettings.siteSettings.shipping,
  }))

  const defaultShippingCharge = shipping?.defaultShippingCharge

  const { currencyCode } = usePrice()

  const address = order?.shippingInfo

  const orderItems = order?.orderItems

  useEffect(() => {
    if (!order || !order?.orderId) {
      history.push("/")
    }
  }, [order])

  const handleOrderItemStatus = status => {
    switch (status) {
      case "Cancelled":
      case "Cancel Processing":
      case "Returned":
      case "Return Processing":
        return true
      default:
        return false
    }
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="invoice-inner">
          <div className="invoice-info" id="invoice_wrapper" ref={componentRef}>
            <div
              className={
                "invoice-header sm:pt-4 sm:pb-1 " +
                `${isNavbar === false ? "d-none" : ""}`
              }
            >
              <div
                className={"row " + `${width < 768 ? "flex-revers-col" : ""}`}
              >
                <div className="col-md-6">
                  <div
                    className={
                      "logo d-flex align-items-center mb-30 " +
                      `${width < 768 ? "d-none" : ""}`
                    }
                  >
                    <a href="#" className="mr-20">
                      <div className="h:40px w:100px mb-3">
                        <img
                          src={LogoFull}
                          alt="logo"
                          className="h-100 w-100"
                        />
                      </div>
                    </a>
                  </div>

                  <div className="text">
                    <h6 className="mb-10">Delivary Address</h6>
                    <strong className="text-brand text-size-20">
                      {address?.name}
                    </strong>{" "}
                    <p>{address?.contactNumber}</p>
                    <p>{address?.email}</p>
                    {address?.address}
                  </div>
                </div>

                <div className={"col-md-6 text-end sm:mb-30"}>
                  <h2 className="text-brand">Order Placed</h2>
                  <h6>
                    Order ID:{" "}
                    <span className="text-brand">{order?.orderId}</span>
                  </h6>
                </div>
              </div>
            </div>

            <div className="invoice-center">
              <div className="table-responsive">
                <table className="table table-striped invoice-table">
                  <thead className="bg-active">
                    <tr>
                      <th>Item </th>
                      {isAccount && <th className="text-start">Item Status</th>}
                      <th className="text-center">Price</th>
                      <th className="text-center">Qty</th>
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(orderItems, (orderItem, key) => (
                      <tr key={key}>
                        <td>
                          <div className="item-desc-1 d-flex align-items-center ">
                            <img
                              src={orderItem?.imageUrl}
                              alt="item"
                              height={"80px"}
                              width={"80px"}
                              style={{
                                filter:
                                  handleOrderItemStatus(
                                    orderItem?.orderItemStatus
                                  ) && "grayscale(1)",
                              }}
                            />

                            <div className="mx-2">
                              <span className="min-w:150px">
                                {language === "Primary"
                                  ? orderItem?.primaryLang?.name
                                  : orderItem?.secondaryLang?.name}
                              </span>
                              <br />
                              <small>
                                {orderItem?.varientValue}{" "}
                                {orderItem?.varientUnit}
                              </small>
                            </div>
                          </div>
                        </td>
                        <td
                          className={
                            "text-start " +
                            `${
                              handleOrderItemStatus(orderItem?.orderItemStatus)
                                ? "text-danger"
                                : "text-info"
                            }`
                          }
                        >
                          {orderItem?.orderItemStatus}
                        </td>
                        <td className="text-center">
                          {currencyCode} {orderItem?.price}
                        </td>
                        <td className="text-center">{orderItem?.quantity}</td>

                        <td className="text-right">
                          {currencyCode}{" "}
                          {orderItem?.price * orderItem?.quantity}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan="4" className="text-end f-w-600">
                        Sub Total
                      </td>
                      <td className="text-right">
                        {" "}
                        {currencyCode} {order?.itemsPrice}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="text-end f-w-600">
                        Delivery Charge
                      </td>
                      <td className="text-right">
                        {" "}
                        {defaultShippingCharge > order?.shippingPrice && (
                          <del>{defaultShippingCharge}</del>
                        )}
                        &nbsp;
                        {currencyCode} {order?.shippingPrice}
                      </td>
                    </tr>
                    {order?.totalofferDiscount ? (
                      <tr>
                        <td colSpan="4" className="text-end f-w-600">
                          Offer Discount
                        </td>
                        <td className="text-right">
                          {" "}
                          {currencyCode} {order?.totalofferDiscount}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {order?.couponDiscount ? (
                      <tr>
                        <td colSpan="4" className="text-end f-w-600">
                          Coupon Discount
                        </td>
                        <td className="text-right">
                          {" "}
                          {currencyCode} {order?.couponDiscount}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {order?.totalprimeDiscount ? (
                      <tr>
                        <td colSpan="4" className="text-end f-w-600">
                          Prime Discount
                        </td>
                        <td className="text-right">
                          {" "}
                          {currencyCode} {order?.totalprimeDiscount}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {order?.totalDiscount ? (
                      <tr>
                        <td colSpan="4" className="text-end f-w-600">
                          Discount
                        </td>
                        <td className="text-right">
                          {" "}
                          {currencyCode} {order?.totalDiscount}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td colSpan="4" className="text-end f-w-600">
                        Grand Total
                      </td>
                      <td className="text-right f-w-600">
                        {" "}
                        {currencyCode} {order?.totalPrice}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="invoice-bottom pb-80">
              <div className="row">
                <div className="col-md-6">
                  <h6 className="mb-15">Payment Info</h6>
                  <p className="font-sm">
                    <strong>Type: </strong>
                    &nbsp;
                    <span className="text-brand">{order?.paymentType}</span>
                  </p>
                  <p className="font-sm">
                    <strong> Status: </strong>

                    {order?.isPaid ? (
                      <span className="text-brand">Paid</span>
                    ) : (
                      <span className="text-warning">Not Paid</span>
                    )}
                  </p>
                </div>
                <div className="col-md-6 text-end sm:pt-4">
                  <h6 className="mb-15">Total Amount</h6>
                  <h3 className="mt-0 mb-0 text-brand">
                    {currencyCode} {order?.totalPrice}
                  </h3>
                  <p className="mb-0 text-muted">Taxes Included</p>
                </div>
              </div>
              <div className="row text-center">
                <div className="hr mt-30 mb-30"></div>
                <p className="mb-0 text-muted">
                  <strong>Note:</strong>This is computer generated receipt and
                  does not require physical signature.
                </p>
              </div>
            </div>
          </div>

          {isFooter === false ? (
            <></>
          ) : (
            <div
              className={
                "invoice-btn-section clearfix d-print-none d-flex align-items-center sm:align-items-end justify-content-end sm:flex-col"
              }
            >
              {width > 768 && (
                <Link
                  to={"#!"}
                  id="invoice_download_btn"
                  onClick={() => exportComponentAsJPEG(componentRef)}
                  className="btn btn-lg btn-custom bg-info btn-download hover-up"
                >
                  {" "}
                  <img src={download} alt="" /> Download{" "}
                </Link>
              )}
              <Link
                to={"/shop-list"}
                id="invoice_download_btn"
                className={"btn btn-lg hover-up "}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <GiShoppingBag className="mx-2" /> <span>Shop More</span>{" "}
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderContent

OrderContent.propTypes = {
  isFooter: PropTypes.bool,
  isNavbar: PropTypes.bool,
  isAccount: PropTypes.bool,
}
