import { addOrRemove } from "store/actions"

const useCartActions = (options, dispatch, cartItems) => {
  // const product = cartItems?.filter(i => i.product?._id === options?.product)[0]
  // const varients = product?.product?.varients
  // const currentVarient = varients?.filter(i => i._id === options?.varient)[0]

  // const totalStock = currentVarient?.stock

  dispatch(addOrRemove(options))
}

export default useCartActions
