import { useSelector } from "react-redux"
import React from "react"
import { useHistory } from "react-router-dom"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { map } from "lodash"
import category1 from "assets/imgs/theme/icons/category-1.svg"

SwiperCore.use([Navigation, Autoplay])

import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import useCloudinary from "constants/UseCloudinaryImage"

const CategorySlider = () => {
  const history = useHistory()

  const selectCategory = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")

    e.preventDefault()
    history.push({
      pathname: `/custom/category/${removeSpace}`,
    })
  }

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))

  function getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <>
      {loading ? (
        <CustomizedLoader
          style={{ height: "100%", minHeight: "200px" }}
          count={6}
          mobileCount={3}
          height={"100%"}
          innerClass={"mx-2"}
        />
      ) : (
        <Swiper
          autoplay={true}
          navigation={{
            prevEl: ".custom_prev_ct1",
            nextEl: ".custom_next_ct1",
          }}
          className="custom-class"
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 8,
            },
            1200: {
              slidesPerView: 10,
            },
          }}
        >
          {map(allCategories, (cat, key) => (
            <SwiperSlide key={key}>
              <div
                className={`card-2 bg-${getRandomNumberBetween(
                  9,
                  15
                )} wow animate__animated animate__fadeInUp`}
                onClick={e => selectCategory(e, cat?.primaryLang?.name)}
              >
                <figure className="img-hover-scale overflow-hidden">
                  <a>
                    <img
                      src={
                        cat?.logoImages[0]?.url
                          ? useCloudinary(cat?.logoImages[0]?.url, "w_120")
                          : category1
                      }
                      alt=""
                    />
                  </a>
                </figure>
                <h6
                  className=""
                  style={{
                    whiteSpace: "break-spaces",
                    margin: "auto",
                    padding: "0 9px",
                    wordWrap: "break-word",
                    fontWeight: "400",
                  }}
                >
                  <span>{cat?.primaryLang?.name}</span>
                </h6>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <div
        className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
        id="carausel-10-columns-arrows"
      >
        <span className="slider-btn slider-prev slick-arrow custom_prev_ct1">
          <i className="fi-rs-arrow-small-left"></i>
        </span>
        <span className="slider-btn slider-next slick-arrow custom_next_ct1">
          <i className="fi-rs-arrow-small-right"></i>
        </span>
      </div>
    </>
  )
}

export default CategorySlider
// export default connect(null, { updateProductCategory })(CategorySlider)
