import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom"

// Import Routes all
import { publicRoutes, AuthProtectedRoute } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"
import Preloader from "components/elements/Preloader"

import "react-perfect-scrollbar/dist/css/styles.css"
import "react-responsive-modal/styles.css"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import "./assets/css/main.css"
import "./assets/css/custom.css"
import "./assets/sass/custom.scss"
import { Notification } from "components/Common/Notification"
import LoginPopup from "pages/user/LoginPopup"
import LocationPopUp from "components/elements/IntroPopup"
import {
  getNearestStore,
  getNearestStoreNoUser,
  getUser,
  getUserLocation,
  getUserPlaces,
  setLanguageAction,
} from "store/actions"
import CartBottomNotification from "components/elements/cartBottomNotification"
import { getDefaultStore, getSiteSettings } from "store/settings/actions"
import UesWindowScrollTop from "constants/uesWindowScrollTop"
import AreaListPopup from "components/elements/AreaListPopup"

const App = props => {
  const token = localStorage.getItem("token_grocery")

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const { isLoginPopup, isLocationPopup, location, cartNoti, multiLanguange } =
    useSelector(state => ({
      isLoginPopup: state.Layout.isLoginPopup,
      isLocationPopup: state.Layout.isLocationPopup,
      location: state.NearbyStore.location,
      cartNoti: state.Layout.cartNoti,
      multiLanguange: state?.SiteSettings.siteSettings?.multiLanguange,
    }))

  useEffect(() => {
    const pre_location = localStorage.getItem("pre_location")
    const lat = parseFloat(pre_location?.split(",")[0])
    const lng = parseFloat(pre_location?.split(",")[1])

    if (pre_location) {
      dispatch(getUserLocation([lat, lng]))
    } else {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          dispatch(
            getUserLocation([
              position.coords.latitude,
              position.coords.longitude,
            ])
          )
        },
        function () {
          dispatch(getDefaultStore())
        }
      )
    }
  }, [dispatch])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(getUser())
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      dispatch(getDefaultStore())
    }
  }, [token])

  useEffect(() => {
    if (location?.length === 2) {
      if (token) {
        dispatch(getNearestStore({ coordinates: location }))
      } else {
        dispatch(getNearestStoreNoUser({ coordinates: location }))
      }

      dispatch(getUserPlaces(location[0], location[1]))
    }
  }, [location])

  useEffect(() => {
    // if (multiLanguange?.isMultiLanguage === true) {
    //   if (multiLanguange?.priority === "english") {
    //     dispatch(setLanguageAction("en"))
    //   } else {
    //     dispatch(setLanguageAction("ar"))
    //   }
    // } else {
    dispatch(setLanguageAction("en"))
    // }
  }, [dispatch])

  useEffect(() => {
    dispatch(getSiteSettings())
  }, [dispatch])

  return (
    <React.Fragment>
      <Notification />
      {isLocationPopup && <LocationPopUp />}
      {isLoginPopup && <LoginPopup />}

      {!loading ? (
        <Router>
          <UesWindowScrollTop />
          <CartBottomNotification />
          <AreaListPopup />
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            {AuthProtectedRoute?.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            {/* {!token && (
              <Route render={() => <Redirect to={{ pathname: "/" }} />} />
            )} */}
            <Route render={() => <Redirect to={{ pathname: "/404" }} />} />,
          </Switch>

          {/* <BottomNotification /> */}
        </Router>
      ) : (
        <Preloader />
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
