import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { VscChromeClose } from "react-icons/vsc"
import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "constants/UsePrice"
import { useDispatch, useSelector } from "react-redux"
import useCartActions from "constants/useCartAction"
import { addOrRemoveFav } from "store/actions"
import useCloudinary from "constants/UseCloudinaryImage"

function CartItemMob({ item, isWish }) {
  const dispatch = useDispatch()
  const product = item?.product

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { cartItems } = useSelector(state => ({
    cartItems: state.Cart.cartItems,
  }))

  const { currencyCode, price, currentVarient } = usePrice(product)

  const deleteFromCart = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "substract",
      quantity: item?.quantity ?? 1,
    }
    useCartActions(options, dispatch)
  }

  const increaseQuantity = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "add",
    }
    useCartActions(options, dispatch, cartItems)
  }

  const decreaseQuantity = item => {
    if (item?.quantity <= 1) {
      return
    } else {
      const options = {
        product: item?.product?._id,
        varient: item?.varient,
        operation: "substract",
      }
      useCartActions(options, dispatch)
    }
  }

  const currentVarient2 = item?.product?.varients?.filter(
    i => i._id === item?.varient
  )[0]

  const handleStockText = () => {
    if (item?.isOutOfStock === true) {
      if (currentVarient2?.stock <= 0) {
        return "Out of Stock"
      } else if (item?.quantity > currentVarient2?.stock) {
        return "Only " + currentVarient2?.stock + " left"
      }
    } else return ""
  }

  const deleteFromWishlist = productId => {
    dispatch(addOrRemoveFav(productId))
  }

  const removeSpace = language?.name?.replace(/ /g, "-")

  return (
    <div
      className="d-flex mb-40 border-b-4 justify-content-between"
      style={{ paddingBottom: "30px" }}
    >
      <div className="d-flex align-items-center flex-1">
        <div className="h:100px w:100px">
          <img src={useCloudinary(product.images[0]?.url, "h_400,w_400")} />
          {handleStockText() && (
            <p className="text-danger pb-1">! {handleStockText()}</p>
          )}
        </div>
        <div className="mx-2 flex-1">
          <h6 className="product-name">
            <Link to={`/products/${removeSpace}`}>{language?.name}</Link>
          </h6>
          <p className="mb-15">
            (
            {item?.varientValue
              ? `${item?.varientValue} ${item?.varientUnit}`
              : currentVarient}
            ) &nbsp;&nbsp; {currencyCode} {price}
          </p>
          {!isWish && (
            <div className="detail-extralink mob_detail_extralink">
              <div className="detail-qty border radius m-0 ">
                <a onClick={() => decreaseQuantity(item)} className="qty-down">
                  <i className="fi-rs-angle-small-down"></i>
                </a>
                <span className="qty-val">{item?.quantity}</span>
                <a onClick={() => increaseQuantity(item)} className="qty-up">
                  <i className="fi-rs-angle-small-up"></i>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      {!isWish && (
        <div className="d-flex flex-col justify-content-between align-items-end mx-2">
          <a
            onClick={() => deleteFromCart(item)}
            className="text-muted d-flex align-items-center justify-content-center"
          >
            <VscChromeClose size={"1.4rem"} />
          </a>
          <h5 className="text-body">
            {currencyCode} {item.quantity * (item?.price ?? price)}
          </h5>
        </div>
      )}

      {isWish && (
        <div className="d-flex flex-col justify-content-center align-items-center mx-2">
          <a onClick={e => deleteFromWishlist(product?._id)}>
            <i className="fi-rs-trash"></i>
          </a>
        </div>
      )}
    </div>
  )
}

export default CartItemMob

CartItemMob.propTypes = {
  item: PropTypes.any,
  isWish: PropTypes.bool,
}
