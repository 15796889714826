import CollectionList2 from "components/ecommerce/CollectionList2"
import React from "react"
import PropTypes from "prop-types"
import { useBanner } from "./useBannerData"

function CollectionG3({ groupDetail }) {
  const banner = useBanner(
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <section className="section-padding mb-4">
      <div className="container">
        <div
          className="section-title mb-4 wow animate__animated animate__fadeIn"
          data-wow-delay="0"
        >
          <h3 className="gp_head">{groupDetail?.primaryLang?.title}</h3>
        </div>
        <div className="row">
          <CollectionList2 listData={banner} type={groupDetail?.type} />
        </div>
      </div>
    </section>
  )
}

export default CollectionG3

CollectionG3.propTypes = {
  groupDetail: PropTypes.object,
}
