import React from "react"
import { MetaTags } from "react-meta-tags"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { LogoSm } from "assets/imgs/dealsToHome"

const MetaData = ({ title, content, keyword, description }) => {
  const { company } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
  }))

  return (
    <MetaTags>
      <title>{`${company?.name ?? "Deals To Home"} | ${title}`}</title>
      <link rel="canonical" href="/" />
      <link rel="icon" href={LogoSm} />
      <meta property="og:title" content={content} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="ecommerce" />
      <meta property="og:url" content="https://osperb.com" />
      <meta property="og:image" content={company?.logo[0]?.url ?? LogoSm} />
      <meta name="keywords" content={keyword}></meta>
    </MetaTags>
  )
}

export default MetaData

MetaData.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  keyword: PropTypes.string,
  description: PropTypes.string,
}
