import FeatchDeals from "components/ecommerce/fetchDeals"
import React from "react"
import { Link } from "react-router-dom"
import { useBanner } from "./useBannerData"
import PropTypes from "prop-types"

function CollectionG2({ groupDetail, col, isHighlight }) {
  const banner = useBanner(
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <>
      <section className="section-padding pb-5">
        <div className="container">
          <div
            className="section-title mb-4 wow animate__animated animate__fadeIn"
            data-wow-delay="0"
          >
            <h3 className="gp_head">{groupDetail?.primaryLang?.title}</h3>

            {groupDetail?.type === "collections" ? (
              <Link to={`/custom/collection`}>
                <span className="show-all">
                  Explore
                  <i className="fi-rs-angle-right"></i>
                </span>
              </Link>
            ) : (
              <Link to={`/custom/category`}>
                <span className="show-all">
                  Explore
                  <i className="fi-rs-angle-right"></i>
                </span>
              </Link>
            )}
          </div>
          <FeatchDeals
            type={groupDetail?.type}
            isHighlight={isHighlight}
            listData={banner}
            col={col}
          />
        </div>
      </section>
    </>
  )
}

export default CollectionG2

CollectionG2.propTypes = {
  groupDetail: PropTypes.object,
  col: PropTypes.number,
  isHighlight: PropTypes.bool,
}
