import React from "react"
import PropTypes from "prop-types"

import empty from "assets/imgs/shop/cart-empty.gif"

function EmptyCartSvg({ style }) {
  return (
    <>
      <div className="d-flex align-items-center flex-revers-col" style={style}>
        <div>
          {style?.isDes ? (
            <>
              <h4 className="text-brand">Your cart is empty!</h4>
              <p className="text-black">
                Explore our wide selection and find something you like
              </p>
            </>
          ) : (
            <p className="text-brand">Your cart is empty!</p>
          )}
        </div>
        <img src={empty} alt="" className="h-100" />
      </div>
    </>
  )
}

export default EmptyCartSvg

EmptyCartSvg.propTypes = {
  style: PropTypes.any,
}
