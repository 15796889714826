import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import useLangSwitch from "constants/LanguageSwicth"
import { useBanner } from "./useBannerData"
import useCloudinary from "constants/UseCloudinaryImage"
import SliderList2 from "components/elements/SliderList2"

function ProductG4({ groupDetail }) {
  const history = useHistory()

  const language = useLangSwitch(
    groupDetail?.primaryLang,
    groupDetail?.secondaryLang
  )

  const product = groupDetail?.products

  const handleBannerData = useBanner(
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12">
          <div>
            <h3 className="mb-4 gp_head">{language?.title}</h3>
            <div className="d-flex custom_small_slider">
              <div className="pt-0 mb-3 border-radius-15 custom_banner">
                <div
                  className="banner-img wow animate__animated animate__fadeInUp h-100"
                  data-wow-delay="0"
                >
                  <img
                    src={useCloudinary(
                      groupDetail?.images[0]?.url,
                      "w_700,h_700"
                    )}
                    className="h-100 border-radius-15"
                    style={{
                      objectFit: "unset",
                      objectPosition: "unset",
                    }}
                    alt=""
                    onClick={() =>
                      history.push(
                        `/custom/${
                          groupDetail?.type === "single collection"
                            ? "collection"
                            : "category"
                        }/${handleBannerData[0]?.primaryLang?.title?.replace(
                          / /g,
                          "-"
                        )}`
                      )
                    }
                  />
                  {/* <div className="banner-text">
                    <h4 className="mb-0">&nbsp;</h4>
                    <Link
                      to={`/custom/collection/${handleBannerData?.primaryLang?.title?.replace(
                        / /g,
                        "-"
                      )}`}
                    >
                      <span className="btn btn-xs text-white">
                        Shop Now <i className="fi-rs-arrow-small-right"></i>
                      </span>
                    </Link>
                  </div> */}
                </div>
              </div>
              <SliderList2 products={product} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductG4

ProductG4.propTypes = {
  groupDetail: PropTypes.object,
}
