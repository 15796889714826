import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { map } from "lodash"
import useCloudinary from "constants/UseCloudinaryImage"

function CollectionList({ listData, col, type }) {
  return (
    <>
      {map(listData, (item, key) => (
        <div className={`col-lg-${col ? col : 4} col-md-6 mb-4`} key={key}>
          <div
            className="banner-img wow animate__animated animate__fadeInUp max-h:300px w-100"
            style={{ height: "260px" }}
            data-wow-delay="0"
          >
            <Link
              to={`/custom/${
                type === "collections" ? "collection" : "category"
              }/${item?.primaryLang?.title?.replace(/ /g, "-")}`}
            >
              <img
                src={useCloudinary(item?.bannerImages[0]?.url ?? "", "w_1200")}
                alt=""
                className="fit-cover h-100 w-100 object-start"
              />
            </Link>
            <div className="banner-text">
              {/* <h4 className="mb-0">&nbsp;</h4> */}
              {/* <Link to="/products">
             <span className="btn btn-xs text-white">
               Shop Now <i className="fi-rs-arrow-small-right"></i>
             </span>
           </Link> */}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default CollectionList

CollectionList.propTypes = {
  listData: PropTypes.any,
  col: PropTypes.number,
  type: PropTypes.any,
}
