import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCategories, getLayoutGroups } from "store/actions"
//componets
import Layout from "components/layout/Layout"
import ProductG1 from "./ProductG1"
import ProductG2 from "./ProductG2"
import ProductG3 from "./ProductG3"
import ProductG4 from "./ProductG4"
import CollectionG1 from "./CollectionG1"
import CollectionG2 from "./CollectionG2"
import CollectionG3 from "./CollectionG3"
import CategorySliderContainer from "./CategorySlider"
import HeroSlider from "./HeroSlider"
import DailyFresh from "./DailyFresh"
import SubBanner from "./SubBanner"
import ProductG5 from "./ProductG5"
import GroupLoader from "components/Common/loaders/GroupLoader"
import MainCatAndSubCatList from "components/ecommerce/filter/mainTagCategory"
import InfiniteScroll from "react-infinite-scroll-component"

export default function Home() {
  const dispatch = useDispatch()

  const {
    layoutGroups,
    loading,
    currentStore,
    allCategories,
    banner,
    defaultStore,
  } = useSelector(state => ({
    layoutGroups: state.LayoutGroup.layoutGroups,
    loading: state.LayoutGroup.loading,
    currentStore: state.NearbyStore.store,
    allCategories: state.Classification.categories,
    banner: state.Banner.banners,
    defaultStore: state.SiteSettings.defaultStore,
  }))

  const subBanners = banner?.subBanners

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  const limit = 2
  const allpages = layoutGroups?.total / limit

  const [allGroups, setAllGroups] = useState([])
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }

  useEffect(() => {
    dispatch(getCategories(1, "all"))
  }, [dispatch])

  useEffect(() => {
    dispatch(getLayoutGroups(pages, limit, currentStoreId, "web"))
  }, [dispatch, currentStoreId, pages, limit])

  const paginatedGp = layoutGroups?.groups

  useEffect(() => {
    if (paginatedGp) {
      setAllGroups([...allGroups, ...paginatedGp])
    }
  }, [layoutGroups, pages])

  const filteredGroupByStore = allGroups?.filter(
    gp => gp.store === currentStoreId
  )

  const ids = filteredGroupByStore?.map(o => o._id)

  const filteredFinalGp = filteredGroupByStore.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )

  const handleLayoutWithProduct = group => {
    if (group.layout === "highlight") return <ProductG5 groupDetail={group} />
    if (group.layout === "list_horizontal")
      return <ProductG2 groupDetail={group} />
    if (group.layout === "list_vertical")
      return <ProductG1 groupDetail={group} />
    if (group.layout === "carousel") return <ProductG3 groupDetail={group} />
    if (group.layout === "list_vertical_sm")
      return <ProductG4 groupDetail={group} />
    if (group.layout === "nested_loop")
      return <DailyFresh groupDetail={group} />
    return <ProductG1 groupDetail={group} />
  }

  const handleLayoutWithOutProduct = group => {
    if (group.layout === "highlight")
      return <CollectionG2 groupDetail={group} col={6} isHighlight={true} />
    if (group.layout === "list_vertical")
      return <CollectionG1 groupDetail={group} col={6} />
    if (group.layout === "list_vertical_sm")
      return <CollectionG1 groupDetail={group} />
    if (group.layout === "list_horizontal")
      return <CollectionG2 groupDetail={group} />
    if (group.layout === "carousel") return <CollectionG3 groupDetail={group} />

    return <CollectionG1 groupDetail={group} />
  }

  const groupTypeHandler = group => {
    if (group.type === "single collection")
      return handleLayoutWithProduct(group)
    if (group.type === "single category") return handleLayoutWithProduct(group)
    if (group.type === "collections") return handleLayoutWithOutProduct(group)
    if (group.type === "categories") return handleLayoutWithOutProduct(group)
    return handleLayoutWithProduct(group)
  }

  return (
    <>
      <Layout noBreadcrumb="d-none">
        <HeroSlider />
        {allCategories?.length >= 1 && <CategorySliderContainer />}
        <div className="container mb-2">
          <MainCatAndSubCatList />
        </div>
        {subBanners?.length >= 1 && <SubBanner />}

        <InfiniteScroll
          scrollThreshold={0.6}
          dataLength={filteredFinalGp?.length ?? 0}
          next={HandlefetchNextPage}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          loader={
            loading && (
              <div className="container m-auto">
                <GroupLoader count={4} />
              </div>
            )
          }
        >
          {filteredFinalGp?.map((group, idx) => (
            <Fragment key={idx}>{groupTypeHandler(group)}</Fragment>
          ))}
        </InfiniteScroll>
      </Layout>
    </>
  )
}

// {loading ? (
//   <div className="container m-auto">
//     <GroupLoader count={4} />
//   </div>
// ) : (
//   <>
//     {layoutGroups?.map((group, idx) => (
//       <Fragment key={idx}>{groupTypeHandler(group)}</Fragment>
//     ))}
//   </>
// )}
