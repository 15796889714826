import { useState } from "react"
import useClickOutside from "util/outsideClick"
import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import logo from "assets/imgs/theme/logo.png"

const MobileMenu = ({ isToggled, toggleClick }) => {
  const [isActive, setIsActive] = useState({
    status: false,
    key: "",
  })

  const handleToggle = key => {
    if (isActive.key === key) {
      setIsActive({
        status: false,
      })
    } else {
      setIsActive({
        status: true,
        key,
      })
    }
  }

  let domNode = useClickOutside(() => {
    setIsActive({
      status: false,
    })
  })
  return (
    <>
      <div
        className={
          isToggled
            ? "mobile-header-active mobile-header-wrapper-style sidebar-visible"
            : "mobile-header-active mobile-header-wrapper-style"
        }
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="mobile-header-logo">
              <Link to="/">
                <img src={logo} alt="logo" height={"40px"} width={"60px"} />
              </Link>
            </div>
            <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
              <button
                className="close-style search-close"
                onClick={toggleClick}
              >
                <i className="icon-top"></i>
                <i className="icon-bottom"></i>
              </button>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div>
              <div className="mobile-menu-wrap mobile-header-border">
                <div className="main-categori-wrap mobile-header-border">
                  {/* <div className="categori-dropdown-wrap categori-dropdown-active-small">
                    <ul>
                      <li>
                        <Link to="/shop-grid-right">
                          <i className="deals2home-font-dress"></i>
                          Women&apos;s Clothing
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>

                <nav>
                  <ul className="mobile-menu" ref={domNode}>
                    <li
                      className={
                        isActive.key == 1
                          ? "menu-item-has-children active"
                          : "menu-item-has-children"
                      }
                    >
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className={
                        isActive.key == 2
                          ? "menu-item-has-children active"
                          : "menu-item-has-children"
                      }
                    >
                      <Link to="/shop-list">shop</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Wish List</Link>
                    </li>
                    <li>
                      <Link to="/quick-purchase">Quick Purchase</Link>
                    </li>

                    <li>
                      <Link to="/custom/category">Categories</Link>
                    </li>
                    <li>
                      <Link to="/custom/collection">Collections</Link>
                    </li>

                    {/* <li>
                      <Link to="/about">About</Link>
                    </li> */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileMenu

MobileMenu.propTypes = {
  isToggled: PropTypes.any,
  toggleClick: PropTypes.any,
}

// <li
//                     className={
//                       isActive.key == 2
//                         ? "menu-item-has-children active"
//                         : "menu-item-has-children"
//                     }
//                   >
//                     <span
//                       className="menu-expand"
//                       onClick={() => handleToggle(2)}
//                     >
//                       <i className="fi-rs-angle-small-down"></i>
//                     </span>
//                     <Link to="/shop-list">shop</Link>
//                     <ul className={isActive.key == 2 ? "dropdown" : "d-none"}>
//                       <li>
//                         <Link to="/shop-grid-right">
//                           Shop Grid – Right Sidebar
//                         </Link>
//                       </li>
//                       <li>
//                         <Link to="/shop-grid-left">
//                           Shop Grid – Left Sidebar
//                         </Link>
//                       </li>

//                     </ul>
//                   </li>
