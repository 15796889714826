import ThreeDotsLoading from "components/Common/loaders/ThreeDotLoading"
import SearchListVertical from "components/elements/SearchListVertical"
import { useOutsideAlerter } from "constants/useOutSideClick"
import { debounce, map } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { getProducts, getProductsByCategory } from "store/actions"

const Search = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const searchRef = useRef(null)
  const location = useLocation()

  const { allCategories, loading, currentStore, products, defaultStore } =
    useSelector(state => ({
      allCategories: state.Classification.categories,
      loading: state.Products.loading,
      products: state.Products.products,
      currentStore: state.NearbyStore.store,
      defaultStore: state.SiteSettings.defaultStore,
    }))

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  const [searchTerm, setSearchTerm] = useState("")
  const [finalInput, setFinalInput] = useState("")
  const [selectedCat, setSelectedCat] = useState("")

  const [showList, setsHowList] = useState(false)

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(searchRef, setOutSide)

  useEffect(() => {
    if (finalInput?.length >= 1) {
      history.push({
        pathname: location?.pathname,
        query: {
          ...location.query,
          isSearch: true,
          search: finalInput,
        },
      })
    } else {
      history.push({
        pathname: location?.pathname,
        query: {
          ...location.query,
          isSearch: false,
          search: finalInput,
        },
      })
    }
  }, [finalInput])

  useEffect(() => {
    const options = {
      searchText: finalInput,
      page: "",
      custom: "all",
      sort: "",
      limit: "",
      store: currentStoreId,
    }

    const CatOptions = {
      ...options,
      custom: selectedCat,
    }

    if (selectedCat && selectedCat !== "All") {
      dispatch(getProductsByCategory(CatOptions))
    } else {
      dispatch(getProducts(options))
    }
  }, [finalInput, currentStoreId, selectedCat])

  const handleInput = e => {
    const finalprodName = products?.products[0]?.primaryLang?.name

    const removeSpace = finalprodName?.replace(/ /g, "-")

    if (e.key === "Enter") {
      e.preventDefault()
      history.push(`/products/${removeSpace}`)
    }
  }

  const printValue = debounce(value => setFinalInput(value), 500)

  const handleChangeSearch = value => {
    printValue(value)
    setSearchTerm(value)
    setsHowList(true)
  }

  useEffect(() => {
    if (outSide === true) {
      setsHowList(false)
      setOutSide(false)
    }
  }, [outSide])

  const [hideList, setHideList] = useState(false)

  useEffect(() => {
    if (
      location?.pathname === "/shop-list" ||
      location?.pathname === "/collection" ||
      location?.pathname === "/category"
    ) {
      setHideList(true)
    } else {
      setHideList(false)
    }
  }, [location?.pathname])

  return (
    <>
      <form>
        <select
          className="select-active"
          onChange={e => setSelectedCat(e.target.value)}
          style={{
            maxWidth: "fit-content",
            width: selectedCat?.length
              ? `${selectedCat?.length * 10}px`
              : "140px",
            paddingRight: 0,
          }}
        >
          <option>All</option>
          {map(allCategories, (cat, key) => (
            <option key={key}>{cat.primaryLang.name}</option>
          ))}
        </select>
        <input
          value={searchTerm}
          onKeyDown={handleInput}
          onChange={e => handleChangeSearch(e.target.value)}
          type="text"
          placeholder="Search"
          style={{ marginLeft: 0 }}
        />
      </form>
      {hideList === false && (
        <>
          {searchTerm && showList && (
            <div
              className="zIndex-full position-absolute bg-white boxshadow-4 border-radius-15 right-0 col-md-4-5 col-lg-4-5"
              ref={searchRef}
            >
              {loading ? (
                <ThreeDotsLoading />
              ) : (
                <>
                  {map(products?.products?.slice(0, 5), (product, key) => (
                    <SearchListVertical
                      setsHowList={setsHowList}
                      product={product}
                      key={key}
                    />
                  ))}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Search
