import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useLocation } from "react-router-dom"
import TableLoader from "components/Common/loaders/TableLoader"
import { useDispatch, useSelector } from "react-redux"
import { getUserOrders, reOrder } from "store/actions"
import { map } from "lodash"
import moment from "moment"
import usePrice from "constants/UsePrice"
import Pagination from "components/ecommerce/Pagination"
import LimitSelect from "components/ecommerce/filter/LimitSelect"

function UserOrders({ activeIndex, handleOnClick }) {
  const loading = false
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { orders, totalOrders } = useSelector(state => ({
    orders: state.Cart.allOrder.orders,
    totalOrders: state.Cart.allOrder.totalOrders,
  }))

  const { currencyCode } = usePrice()
  const query = location?.query

  const handleOrderDetailsView = id => {
    handleOnClick(3, id)
  }

  const handleOrderStatus = status => {
    if (status === "Processing") return "text-black"
    if (status === "Shipped") return "text-green"
    if (status === "Delivered") return "text-info"
    if (status === "Returned") return "text-danger"
    if (status === "Cancelled") return "text-danger"
  }

  const handleOrderAgain = id => {
    dispatch(reOrder(id, history))
  }

  //pagination
  let [pagination, setPagination] = useState([])
  const [limit, setLimit] = useState(10)

  let [pages, setPages] = useState(Math.ceil(totalOrders / limit))
  let [currentPage, setCurrentPage] = useState(1)

  const showPagination = pages && pages

  const cratePagination = () => {
    let arr = new Array(Math.ceil(totalOrders / limit))
      .fill()
      .map((_, idx) => idx + 1)

    setPagination(arr)
    setPages(Math.ceil(totalOrders / limit))
  }

  useEffect(() => {
    if (totalOrders) {
      cratePagination()
    }
  }, [limit, pages, totalOrders])

  const next = () => {
    setCurrentPage(page => page + 1)
  }

  const prev = () => {
    setCurrentPage(page => page - 1)
  }

  const handleActive = item => {
    setCurrentPage(item)
  }

  let start = Math.floor((currentPage - 1) / showPagination) * showPagination
  let end = start + showPagination
  const getPaginationGroup = pagination.slice(start, end)

  useEffect(() => {
    dispatch(getUserOrders(currentPage, limit))
  }, [dispatch, currentPage, limit])

  useEffect(() => {
    if (query?.limit) {
      setLimit(query?.limit)
      setCurrentPage(1)
    }
  }, [query?.limit])

  return (
    <div
      className={
        activeIndex === 2 ? "tab-pane fade active show" : "tab-pane fade "
      }
    >
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h5 className="mb-0 md:mt-50">Your Orders</h5>
          <div className="sort-by-product-area ">
            <div className="sort-by-cover order_limit">
              <LimitSelect pathname={"/user-account"} />
            </div>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <TableLoader />
          ) : (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr className="bg-pista ">
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Payment</th>
                    <th>
                      <h6 className="my-2">Actions</h6>
                    </th>
                  </tr>
                  <tr></tr>
                </thead>
                <tbody>
                  {map(orders, (order, key) => (
                    <tr className="border-none border-b-4" key={key}>
                      <td>
                        <Link
                          to="#!"
                          className="text-black "
                          style={{ textDecoration: "underline" }}
                          onClick={() => handleOrderDetailsView(order?._id)}
                        >
                          {order?.orderId}
                        </Link>
                      </td>
                      <td className="white-space-nowrap">
                        {moment(order?.createdAt).format("MMM DD, YYYY")}
                      </td>
                      <td className={handleOrderStatus(order?.orderStatus)}>
                        {order?.orderStatus}
                      </td>
                      <td>
                        <span className="text-warning">
                          {currencyCode} {order?.totalPrice}
                        </span>{" "}
                      </td>
                      <td>
                        <span
                          className={
                            order?.paymentType === "Cash on Delivery"
                              ? "text-gray"
                              : "text-green"
                          }
                        >
                          {order?.paymentType}
                        </span>
                      </td>
                      <td className="d-flex align-items-center justify-content-between">
                        <Link
                          to="#!"
                          onClick={() => handleOrderDetailsView(order?._id)}
                          className="btn-small d-block my-3"
                        >
                          View
                        </Link>
                        <Link
                          to="#!"
                          onClick={() => handleOrderAgain(order?._id)}
                          className="btn-sm btn btn-custom bg-info  btn-download hover-up"
                        >
                          Order Again
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
          <nav aria-label="Page navigation example">
            <Pagination
              getPaginationGroup={getPaginationGroup}
              currentPage={currentPage}
              pages={pages}
              next={next}
              prev={prev}
              handleActive={handleActive}
            />
          </nav>
        </div>
      </div>
    </div>
  )
}

export default UserOrders

UserOrders.propTypes = {
  activeIndex: PropTypes.any,
  handleOnClick: PropTypes.func,
}
