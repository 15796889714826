import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { map } from "lodash"
import useCloudinary from "constants/UseCloudinaryImage"

function FeatchDeals({ isHighlight, listData, col, type }) {
  return (
    <>
      <div className="row">
        {map(listData, (item, key) => (
          <div
            className={"col-lg-4 col-md-6 col-xl-" + `${col ? col : 3}`}
            key={key}
          >
            <div
              className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp"
              data-wow-delay="0"
            >
              <div className="product-img-action-wrap">
                <div className="product-img">
                  <Link
                    to={`/custom/${
                      type === "collections" ? "collection" : "category"
                    }/${item?.primaryLang?.title?.replace(/ /g, "-")}`}
                    className="h-100 w-100"
                  >
                    {isHighlight ? (
                      <img
                        src={useCloudinary(
                          item?.bannerImages[0]?.url,
                          "w_1200"
                        )}
                        alt=""
                        className="fit-contain object-end"
                      />
                    ) : (
                      <img
                        src={useCloudinary(item?.logoImages[0]?.url, "w_800")}
                        alt=""
                        className="fit-contain object-end"
                      />
                    )}
                  </Link>
                </div>
              </div>
              <div
                className="product-content-wrap max-w:300px"
                style={{
                  marginLeft: col && "1rem",
                  marginTop: col && "-110px",
                }}
              >
                <div className="deals-content">
                  <h2>
                    <Link to={"#!"}>
                      <span>
                        {item?.primaryLang?.title
                          ? item?.primaryLang?.title
                          : item?.primaryLang?.name}
                      </span>
                    </Link>
                  </h2>

                  <div className="product-card-bottom">
                    <div className="product-price">
                      {" "}
                      {item?.primaryLang?.description}
                    </div>
                    <div className="add-cart">
                      <Link
                        to={`${
                          item?.primaryLang?.title
                            ? `/custom/collection/${item?.primaryLang?.title?.replace(
                                / /g,
                                "-"
                              )}`
                            : "/custom/category"
                        }`}
                        className="add"
                      >
                        <i className="fi-rs-shopping-cart mr-5"></i>Explore{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
export default FeatchDeals

FeatchDeals.propTypes = {
  col: PropTypes.number,
  listData: PropTypes.any,
  isHighlight: PropTypes.bool,
  type: PropTypes.any,
}
