import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FaSpinner } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import usePrice from "constants/UsePrice"
import useLangSwitch from "constants/LanguageSwicth"
import useCartActions from "constants/useCartAction"
import { BsExclamationCircle } from "react-icons/bs"
import useCloudinary from "constants/UseCloudinaryImage"

const CartItem = ({ item }) => {
  const product = item?.product
  const dispatch = useDispatch()

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { cartItems, loading } = useSelector(state => ({
    cartItems: state.Cart.cartItems,
    loading: state.Cart.addOrRemoveLoad,
  }))

  const { currencyCode, oldPrice, returnText, price } = usePrice(product)

  const increaseQuantity = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "add",
    }
    useCartActions(options, dispatch, cartItems)
  }

  const decreaseQuantity = item => {
    if (item?.quantity <= 1) {
      return
    } else {
      const options = {
        product: item?.product?._id,
        varient: item?.varient,
        operation: "substract",
      }
      useCartActions(options, dispatch)
    }
  }

  const deleteFromCart = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "substract",
      quantity: item?.quantity ?? 1,
    }
    useCartActions(options, dispatch)
  }

  const currentVarient = item?.product?.varients?.filter(
    i => i._id === item?.varient
  )[0]

  const handleStockText = () => {
    if (item?.isOutOfStock === true) {
      if (currentVarient?.stock <= 0) {
        return "Out of Stock"
      } else if (item?.quantity > currentVarient?.stock) {
        return "Only " + currentVarient?.stock + " left"
      }
    } else return ""
  }
  const removeSpace = language?.name?.replace(/ /g, "-")

  return (
    <tr className="border-none border-b-4 mt-1 sm:p-2 sm:d-flex sm:flex-col">
      <td className="image product-thumbnail">
        <Link to={`/products/${removeSpace}`}>
          <img src={useCloudinary(product.images[0]?.url, "h_350,w_350")} />
        </Link>
        {handleStockText() && (
          <p className="text-danger d-flex align-items-center">
            {" "}
            <BsExclamationCircle className="text-danger mx-2" />{" "}
            {handleStockText()}
          </p>
        )}
        {item?.isReturnViable && (
          <p className="d-flex align-items-center">
            <BsExclamationCircle className="text-green mx-2" /> {returnText}
          </p>
        )}
      </td>

      <td className="product-des product-name px-4 ">
        <h6 className="product-name">
          <Link to={`/products/${removeSpace}`}>
            {language?.name} ({item?.varientValue}&nbsp;
            {item?.varientUnit})
          </Link>
        </h6>
      </td>
      <td className="price sm:display-between" data-title="Price">
        <div className="d-flex align-items-end justify-content-start">
          <p className="old-price mr-1">
            <del>{oldPrice}</del>
          </p>
          <h4 className="text-brand">
            {currencyCode} {item?.price ?? price}
          </h4>
        </div>
      </td>
      <td
        className="text-start detail-info sm:display-between"
        data-title="Quantity"
      >
        <div className="detail-extralink ">
          <div className="detail-qty border radius m-0 ">
            <a onClick={() => decreaseQuantity(item)} className="qty-down">
              <i className="fi-rs-angle-small-down"></i>
            </a>
            <span className="qty-val d-flex align-items-center">
              {item.quantity}
              {loading && <FaSpinner className="mx-2 spinner" />}
            </span>
            <a onClick={() => increaseQuantity(item)} className="qty-up">
              <i className="fi-rs-angle-small-up"></i>
            </a>
          </div>
        </div>
      </td>
      <td
        className="text-right px-2 sm:px-0 sm:display-between"
        data-title="Subtotal"
      >
        <h4 className="text-body">
          {currencyCode} {item.quantity * (item?.price ?? price)}
        </h4>
      </td>
      <td
        className="action px-2 sm:px-0 sm:display-between"
        data-title="Remove"
      >
        <a
          onClick={() => deleteFromCart(item)}
          className="text-muted d-flex align-items-center justify-content-center"
        >
          <i className="fi-rs-trash"></i>
        </a>
      </td>
    </tr>
  )
}

export default CartItem

CartItem.propTypes = {
  item: PropTypes.any,
}
