import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USER_CART,
  COUPON_VALIDATE,
  MAKE_ORDER,
  GET_USER_ORDERS,
  GET_ORDER_DETAILS,
  RETURN_ORDER,
  RE_ORDER,
  CANCEL_ORDER,
  ADD_OR_REMOVE,
  ADD_OR_REMOVE_FAV,
  CREATE_RAZORPAY_ORDER,
  DELETE_USER_ADDRESS,
  GET_FAVOURITE_ITEMS,
  GET_QUICKLIST_ITEMS,
  GET_USER_ADDRESS,
  SET_DEFUALT_ADDRESS,
  UPDATE_USER_ADDRESS,
  VERIFY_RAZORPAY_ORDER,
} from "./actionTypes"

import {
  addOrRemoveError,
  addOrRemoveFavError,
  addOrRemoveFavSuccess,
  addOrRemoveSuccess,
  cancelOrderFail,
  cancelOrderSuccess,
  couponValidateFail,
  couponValidateSuccess,
  createRazorpayOrderFail,
  createRazorpayOrderSuccess,
  deleteUserAddressFail,
  deleteUserAddressSuccess,
  getFavouriteItems,
  getFavouriteItemsFail,
  getFavouriteItemsSuccess,
  getOrderDetailsFail,
  getOrderDetailsSuccess,
  getQuicklistFail,
  getQuicklistSuccess,
  getUserAddressFail,
  getUserAddressSuccess,
  getUserCart,
  getUserCartFail,
  getUserCartSuccess,
  getUserOrdersFail,
  getUserOrdersSuccess,
  makeUserOrderFail,
  makeUserOrderSuccess,
  reOrderFail,
  reOrderSuccess,
  returnOrderFail,
  returnOrderSuccess,
  setDefualtAddressFail,
  setDefualtAddressSuccess,
  UpdateUserAddressFail,
  UpdateUserAddressSuccess,
  verifyRazorpayOrderFail,
  verifyRazorpayOrderSuccess,
} from "./actions"

import { ApiPut, del, get, post } from "helpers/api_methods"
import { showToasterAction } from "store/actions"
import { showCartNotificationAction } from "store/actions"

//user cart
function getUserCartApi() {
  return get("/cart/user")
}
function cartAddOrRemoveApi(options) {
  return post("/cart/add-remove", options)
}

//coupon
function couponValidateApi(coupon) {
  return post("/coupon/user/validate", { couponCode: coupon })
}

//order
function makeNewOrderApi(input) {
  return post("/order/user/new", input)
}
function fetchUserOrderApi({ page, limit }) {
  return get(`/order/user?page=${page ?? 1}&limit=${limit ?? 10}`)
}
function fetchOrderDetailApi(orderId) {
  return get(`/order/user/single/${orderId}`)
}
function cancelOrderApi(input) {
  return post(`/order/user/cancel/${input?.id}`, {
    cancelOrderItems: input?.cancelOrderItems,
  })
}
function returnOrderApi(input) {
  return post(`/order/user/return/${input?.id}`, {
    returnOrderItems: input?.returnOrderItems,
  })
}
function reOrderCartApi(orderId) {
  return post(`/cart/add/order/${orderId}`)
}

//payment
function createRazorpayOrderApi() {
  return get(`/payment/razorpay/order`)
}
function verifyRazorpayOrderApi(razorpayRes) {
  return post(`/payment/razorpay/verify`, razorpayRes)
}

//address
function getUserAddressApi() {
  return get("/user/address")
}
function updateUserAddressApi({ input, addressId }) {
  return ApiPut(`/user/address/${addressId}`, input)
}
function deleteUserAddressApi(addressId) {
  return del(`/user/address/${addressId}`)
}
function setDefualtAddressApi({ addressId, input }) {
  return post(`/user/address/default/${addressId}`, input)
}

//favourite
function getFavouriteItemApi({ page, limit }) {
  return get(`/favourite/user?page=${page ?? 1}&limit=${limit ?? 10}`)
}
function addOrRemoveFavouriteItemApi(productId) {
  return post(`/favourite/user/addOrRemove`, { product: productId })
}

//quick purchase
function getQuicklistItemApi({ page, limit }) {
  return get(`/quick-list-item/user?page=${page ?? 1}&limit=${limit ?? 10}`)
}

/////
//user cart
function* fetchUserCart() {
  try {
    const response = yield call(getUserCartApi)
    yield put(getUserCartSuccess(response))
  } catch (error) {
    yield put(getUserCartFail(error))
  }
}
function* cartAddOrRemove({ payload }) {
  try {
    const response = yield call(cartAddOrRemoveApi, payload)
    yield put(
      addOrRemoveSuccess({ ...response, myVarientId: payload?.varient })
    )
    if (response?.message === "Wrong authentication token") {
      yield put(
        showToasterAction({
          data: {
            title: "Cart",
            message: "Please login to add items to the cart ",
          },
          type: "warning",
        })
      )
    } else if (response?.message) {
      yield put(
        showToasterAction({
          data: {
            title: "Cart",
            message: response?.message ? response?.message : "try again",
          },
          type: "warning",
        })
      )
    } else {
      yield put(
        showToasterAction({
          data: { title: "Cart", message: "Cart Updated" },
          type: "success",
        })
      )
      yield put(showCartNotificationAction())
    }
  } catch (error) {
    yield put(addOrRemoveError(error))
    yield put(
      showToasterAction({
        data: { message: "please try again" },
        type: "warning",
      })
    )
  }
}
//coupon
function* couponValidate({ payload }) {
  try {
    const response = yield call(couponValidateApi, payload)
    if (typeof response === "number") {
      yield put(
        couponValidateSuccess({
          success: "Coupon " + payload + " Applied",
          response,
        })
      )
      yield put(
        showToasterAction({
          data: { message: "coupon Applied" },
          type: "success",
        })
      )
      yield put(getUserCart())
    } else {
      yield put(
        couponValidateSuccess({
          success: "Coupon " + payload + " Applied",
          response,
        })
      )
    }
  } catch (error) {
    if (error.response?.data?.message) {
      yield put(
        showToasterAction({
          data: { message: error.response?.data?.message },
          type: "error",
        })
      )
    }
    yield put(couponValidateFail(error.response?.data?.message))
  }
}

//order
function* makeNewOrder({ payload: { input, history } }) {
  try {
    const response = yield call(makeNewOrderApi, input)
    if (response?.message) {
      yield put(makeUserOrderSuccess(response))
    }
    if (response?.orderId) {
      yield put(makeUserOrderSuccess(response))
      history.replace("/order-success")
      yield put(
        showToasterAction({
          data: {
            title: "Order",
            message: "Order Created Successfully",
          },
          type: "success",
        })
      )
    }
  } catch (error) {
    yield put(makeUserOrderFail(error))
  }
}

function* fetchUserOrder({ payload }) {
  try {
    const response = yield call(fetchUserOrderApi, payload)
    yield put(getUserOrdersSuccess(response))
  } catch (error) {
    yield put(getUserOrdersFail(error))
  }
}
function* cancelOrder({ payload }) {
  try {
    const response = yield call(cancelOrderApi, payload)
    yield put(cancelOrderSuccess(response))
    yield put(
      showToasterAction({
        data: {
          title: "Order",
          message: "Order Status Updated",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(cancelOrderFail(error))
  }
}
function* returnOrder({ payload }) {
  try {
    const response = yield call(returnOrderApi, payload)
    yield put(returnOrderSuccess(response))
  } catch (error) {
    yield put(returnOrderFail(error))
  }
}
function* fetchOrderDetail({ payload }) {
  try {
    const response = yield call(fetchOrderDetailApi, payload)
    yield put(getOrderDetailsSuccess(response))
  } catch (error) {
    yield put(getOrderDetailsFail(error))
  }
}
function* reOrderCart({ payload: { orderId, history } }) {
  try {
    const response = yield call(reOrderCartApi, orderId)
    yield put(reOrderSuccess(response))
    history.push("/checkout")
  } catch (error) {
    yield put(reOrderFail(error))
  }
}

//payment
function* createRazorpayOrder() {
  try {
    const response = yield call(createRazorpayOrderApi)
    yield put(createRazorpayOrderSuccess(response))
  } catch (error) {
    yield put(createRazorpayOrderFail(error))
  }
}
function* verifyingRazorpayOrder({ payload: { razorpayRes } }) {
  try {
    const response = yield call(verifyRazorpayOrderApi, razorpayRes)
    if (response) {
      yield put(
        showToasterAction({
          data: {
            title: "Payment",
            message: response?.message,
          },
          type: "success",
        })
      )
    }
    yield put(verifyRazorpayOrderSuccess(response))
  } catch (error) {
    yield put(verifyRazorpayOrderFail(error))
  }
}

//address
function* fetchUserAddress() {
  try {
    const response = yield call(getUserAddressApi)
    yield put(getUserAddressSuccess(response))
  } catch (error) {
    yield put(getUserAddressFail(error))
  }
}

function* updateUserAddress({ payload }) {
  try {
    const response = yield call(updateUserAddressApi, payload)
    yield put(UpdateUserAddressSuccess(response))
    yield put(
      showToasterAction({
        data: {
          title: "Shipping Address",
          message: "Shipping Address Updated Successfully",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(UpdateUserAddressFail(error))
  }
}

function* setDefualtUserAddress({ payload }) {
  try {
    const response = yield call(setDefualtAddressApi, payload)
    yield put(setDefualtAddressSuccess(response))
    yield put(
      showToasterAction({
        data: {
          title: "Shipping Address",
          message: "Shipping Address Updated Successfully",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(setDefualtAddressFail(error))
  }
}

//favourite

function* fetchFavouriteItem({ payload }) {
  try {
    const response = yield call(getFavouriteItemApi, payload)
    yield put(getFavouriteItemsSuccess(response))
  } catch (error) {
    yield put(getFavouriteItemsFail(error))
  }
}
function* addOrRemoveFavouriteItem({ payload }) {
  try {
    const response = yield call(addOrRemoveFavouriteItemApi, payload)
    yield put(addOrRemoveFavSuccess(response))
    yield put(getFavouriteItems(1, 10))
    yield put(
      showToasterAction({
        data: {
          title: "Wishlist",
          message: "Wishlist Updated",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(addOrRemoveFavError(error))
  }
}

//quick purchase

function* fetchQuicklistItems({ payload }) {
  try {
    const response = yield call(getQuicklistItemApi, payload)
    yield put(getQuicklistSuccess(response))
  } catch (error) {
    yield put(getQuicklistFail(error))
  }
}

function* deleteUserAddress({ payload }) {
  try {
    const response = yield call(deleteUserAddressApi, payload)
    yield put(deleteUserAddressSuccess(response))
    yield put(
      showToasterAction({
        data: {
          title: "Shipping Address",
          message: "Shipping Address Updated Successfully",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(deleteUserAddressFail(error))
  }
}

function* cartSaga() {
  yield takeEvery(GET_USER_CART, fetchUserCart)
  yield takeEvery(ADD_OR_REMOVE, cartAddOrRemove)
  yield takeEvery(GET_USER_ADDRESS, fetchUserAddress)
  yield takeEvery(COUPON_VALIDATE, couponValidate)
  yield takeEvery(MAKE_ORDER, makeNewOrder)
  yield takeEvery(UPDATE_USER_ADDRESS, updateUserAddress)
  yield takeEvery(DELETE_USER_ADDRESS, deleteUserAddress)
  yield takeEvery(GET_USER_ORDERS, fetchUserOrder)
  yield takeEvery(GET_ORDER_DETAILS, fetchOrderDetail)
  yield takeEvery(SET_DEFUALT_ADDRESS, setDefualtUserAddress)
  yield takeEvery(CREATE_RAZORPAY_ORDER, createRazorpayOrder)
  yield takeEvery(VERIFY_RAZORPAY_ORDER, verifyingRazorpayOrder)
  yield takeEvery(RE_ORDER, reOrderCart)
  yield takeEvery(GET_FAVOURITE_ITEMS, fetchFavouriteItem)
  yield takeEvery(ADD_OR_REMOVE_FAV, addOrRemoveFavouriteItem)
  yield takeEvery(GET_QUICKLIST_ITEMS, fetchQuicklistItems)
  yield takeEvery(CANCEL_ORDER, cancelOrder)
  yield takeEvery(RETURN_ORDER, returnOrder)
}

export default cartSaga
