import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import category1 from "assets/imgs/theme/icons/category-1.svg"

import { useDispatch, useSelector } from "react-redux"
import { getCategories } from "store/actions"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import useCloudinary from "constants/UseCloudinaryImage"

const CategoryGrid = ({ show, wide }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))

  useEffect(() => {
    dispatch(getCategories("", "all"))
  }, [dispatch])

  const handleSingleViewClick = catName => {
    const removeSpace = catName?.replace(/ /g, "-")

    history.push({
      pathname: `/custom/category/${removeSpace}`,
      state: "category",
    })
  }

  //pagination
  // let [pagination, setPagination] = useState([])

  // const numOfPage = allCategories?.length

  // const [limit, setLimit] = useState(10)
  // let [pages, setPages] = useState(Math.ceil(numOfPage / limit))
  // let [currentPage, setCurrentPage] = useState(1) //state to call api

  // const showPagination = pages ? pages : 5

  // const cratePagination = () => {
  //   let arr = new Array(Math.ceil(numOfPage / limit))
  //     .fill()
  //     .map((_, idx) => idx + 1)

  //   setPagination(arr)
  //   setPages(Math.ceil(numOfPage / limit))
  // }

  // useEffect(() => {
  //   cratePagination()
  // }, [limit, pages, numOfPage])

  // const next = () => {
  //   setCurrentPage(page => page + 1)
  // }

  // const prev = () => {
  //   setCurrentPage(page => page - 1)
  // }

  // const handleActive = item => {
  //   setCurrentPage(item)
  // }

  // let start = Math.floor((currentPage - 1) / showPagination) * showPagination
  // let end = start + showPagination
  // const getPaginationGroup = pagination.slice(start, end)

  //end of pagination

  return (
    <>
      {loading ? (
        <ProductListLoader />
      ) : (
        <>
          {allCategories?.map((item, i) => (
            <article
              className={
                wide
                  ? "col-xl-2 col-lg-2 col-md-4  col-sm-6 col-6 text-center hover-up mb-30 animated"
                  : "col-xl-2 col-lg-2 col-md-4  col-sm-6 col-6 text-center hover-up mb-30 animated"
              }
              key={i}
            >
              <div className="post-thumb">
                <span
                  onClick={() => handleSingleViewClick(item?.primaryLang?.name)}
                >
                  <img
                    className="border-radius-15"
                    src={
                      item?.logoImages[0]?.url
                        ? useCloudinary(item?.logoImages[0]?.url, "w_200")
                        : category1
                    }
                    alt=""
                    style={{
                      height: "100px",
                      width: "150px",
                      objectFit: "contain",
                      padding: "20px ",
                      paddingBottom: "0",
                    }}
                  />
                </span>
              </div>
              <div className="entry-content-2">
                <h6 className="mb-10 font-sm">
                  <Link to={`/custom/category`}>
                    <span className="entry-meta text-black">
                      {item?.primaryLang?.name}
                    </span>
                  </Link>
                </h6>
                <h4 className="post-title mb-15">
                  <Link to="/custom/category">{item?.title}</Link>
                </h4>
                <div className="entry-meta font-xs color-grey mt-10 pb-10">
                  <div>
                    <span className="post-on mr-10">
                      {item?.primaryLang?.description}
                    </span>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </>
      )}

      {/* <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
        <nav aria-label="Page navigation example">
          <Pagination
            getPaginationGroup={getPaginationGroup}
            currentPage={currentPage}
            pages={pages}
            next={next}
            prev={prev}
            handleActive={handleActive}
          />
        </nav>
      </div> */}
    </>
  )
}

export default CategoryGrid

CategoryGrid.propTypes = {
  show: PropTypes.any,
  wide: PropTypes.any,
}
