import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ProductTab from "../../elements/ProductTab"
import RelatedSlider from "../../sliders/Related"
import ThumbSlider from "../../sliders/Thumb"
import PropTypes from "prop-types"
import React from "react"

import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "constants/UsePrice"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { FaHeart, FaRegHeart, FaSpinner } from "react-icons/fa"
import useCartActions from "constants/useCartAction"
import {
  addOrRemoveFav,
  showAreasListAction,
  showLoginPopupAction,
  showToasterAction,
} from "store/actions"

import { Markup } from "interweave"

const ProductDetails = ({ product }) => {
  const [quantity, setQuantity] = useState(1)
  const [varients, setVarients] = useState({})

  const [seeMore, setSeeMore] = useState(false)

  const dispatch = useDispatch()

  const token = localStorage.getItem("token_grocery")

  const { addOrRemoveLoad, cartItems, relatedProd, isDefaultStore, shipping } =
    useSelector(state => ({
      addOrRemoveLoad: state.Cart.addOrRemoveLoad,
      cartItems: state.Cart.cartItems,
      relatedProd: state.Products.relatedProduct,
      isDefaultStore: state.NearbyStore.store.isDefault,
      shipping: state.SiteSettings.siteSettings.shipping,
    }))

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, stock, currentVarient } = usePrice(
    product,
    varients
  )

  const ReturnDuration = shipping?.returnDuration

  const handleCart = product => {
    const options = {
      product: product?._id,
      varient: varients?._id ? varients?._id : product?.varients[0]?._id,
      quantity: quantity,
      operation: "add",
    }
    if (!token) {
      dispatch(showLoginPopupAction())
    } else if (isDefaultStore === true) {
      dispatch(
        showToasterAction({
          data: {
            title: "Change your location",
            message: "this is defualt store, change your location",
          },
          type: "default",
        })
      )
    } else if (stock >= 1) {
      useCartActions(options, dispatch, cartItems)
    }
  }

  const [wishlistActive, setWishlistActive] = useState(false)

  const handleWishlist = productId => {
    dispatch(addOrRemoveFav(productId))
    setWishlistActive(!wishlistActive)
  }

  const handleVarients = varient => {
    if (varient?._id === varients?._id) {
      setVarients({})
    } else {
      setVarients(varient)
    }
  }

  const handleDecreaseQty = () => {
    setQuantity(quantity > 1 ? quantity - 1 : 1)
  }

  const handleIncreaseQty = () => {
    if (stock === quantity) {
      setQuantity(quantity)
    } else {
      setQuantity(quantity + 1)
    }
  }

  const handleAvailableList = () => {
    dispatch(showAreasListAction())
  }

  useEffect(() => {
    const varient = product?.varients[0]
    setVarients(varient)
  }, [product])

  const diff = Math.abs(oldPrice - price)
  const discountPercentage = Math.floor((diff / oldPrice) * 100)

  return (
    <>
      <section className="mt-50 mb-50">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-xl-10 col-lg-4-5 m-auto">
              <div className="product-detail accordion-detail">
                <div className="row mb-50  mt-30">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="detail-gallery">
                      <div className="product-image-slider">
                        <ThumbSlider product={product} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="detail-info  pr-30 pl-30">
                      {stock <= 0 && (
                        <span className="stock-status out-stock">
                          {" "}
                          Out Of Stock{" "}
                        </span>
                      )}
                      {stock <= 3 && stock !== 0 && (
                        <span className="stock-status in-stock mx-2">
                          Hurry, Only a few left!
                        </span>
                      )}
                      {product?.barcode && <p>{product?.barcode}</p>}
                      <h2 className="title-detail font-mainHead">
                        {language?.name} ({currentVarient})
                      </h2>

                      <div className="clearfix product-price-cover">
                        <div className="product-price primary-color float-left">
                          <span className="current-price font-bigger text-brand">
                            {currencyCode}
                            {price}
                          </span>
                          <span>
                            {oldPrice && (
                              <span className="price-per mx-3">
                                {discountPercentage}% off
                              </span>
                            )}
                            {oldPrice && (
                              <span className="old-price font-md ml-15 font-normal">
                                {oldPrice}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="short-desc mb-30">
                        <span className="font-lg">
                          <Markup
                            content={language?.description?.slice(
                              0,
                              `${seeMore ? language?.description?.length : 390}`
                            )}
                          />
                          {language?.description?.length >= 390 && (
                            <span
                              className="text-primary"
                              style={{ textDecoration: "underline" }}
                              onClick={() => setSeeMore(!seeMore)}
                            >
                              {!seeMore ? "read more" : "show less"}
                            </span>
                          )}
                        </span>
                      </div>

                      <div className="attr-detail attr-size">
                        <ul className="list-filter size-filter font-small">
                          {map(product?.varients, (varient, key) => (
                            <li
                              className={`${
                                varients?._id === varient?._id ? "active" : ""
                              }`}
                              key={key}
                            >
                              <Link
                                to={"#!"}
                                onClick={() => handleVarients(varient)}
                              >
                                {varient?.value}
                                {varient?.unit}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                      <div className="detail-extralink">
                        <div className="detail-qty border radius">
                          <a onClick={handleDecreaseQty} className="qty-down">
                            <i className="fi-rs-angle-small-down"></i>
                          </a>
                          <span className="qty-val">{quantity}</span>
                          <a onClick={handleIncreaseQty} className="qty-up">
                            <i className="fi-rs-angle-small-up"></i>
                          </a>
                        </div>
                        <div className="product-extra-link2">
                          <button
                            onClick={e => handleCart(product)}
                            className={
                              "button button-add-to-cart " +
                              `${
                                (isDefaultStore || !token || stock <= 0) &&
                                "bg-gray text-white opacity-half cursor-notAllowed"
                              }`
                            }
                            title={
                              !token
                                ? "Please Login"
                                : "Not available in your Area"
                            }
                          >
                            Add to cart{" "}
                            {addOrRemoveLoad && (
                              <FaSpinner className="mx-2 spinner" />
                            )}
                          </button>

                          <a
                            aria-label="Add To Wishlist"
                            className="action-btn bg-white"
                            onClick={e => handleWishlist(product?._id)}
                          >
                            {wishlistActive ? (
                              <FaHeart
                                className="text-danger"
                                size={"1.2rem"}
                                style={{ marginTop: "-6px" }}
                              />
                            ) : (
                              <FaRegHeart
                                size={"1.2rem"}
                                className="text-black"
                                style={{ marginTop: "-6px" }}
                              />
                            )}
                          </a>
                        </div>
                      </div>

                      {stock === quantity && (
                        <span className="stock-status bg-yellow-light">
                          You have reached the maximum Quantity allowed
                        </span>
                      )}

                      {!token && (
                        <p className="stock-status bg-yellow-light">
                          Login to add items to cart
                        </p>
                      )}
                      <div>
                        {isDefaultStore && (
                          <>
                            <p className="m-0 p-0 mb-1 text-warning">
                              Not available in your Area
                            </p>
                            <Link
                              to={"#"}
                              className="bg-green rounded"
                              style={{ padding: "5px" }}
                              onClick={() => handleAvailableList()}
                            >
                              View Available Areas
                            </Link>
                          </>
                        )}
                      </div>

                      <ul className="product-meta font-xs color-grey mt-30">
                        <li className="mb-5">
                          {ReturnDuration && (
                            <span>
                              Easy {ReturnDuration} days{" "}
                              <Link to={"/return-policy"}>
                                return and exchanges{" "}
                              </Link>
                              available!
                            </span>
                          )}
                        </li>
                        <li className="mb-5">
                          Category:&nbsp;
                          {map(product?.categories, (cat, key) => (
                            <Link to={"/custom/category"} key={key}>
                              {cat?.primaryLang?.name},&nbsp;
                            </Link>
                          ))}
                        </li>

                        <li>
                          Availability:
                          <span className="in-stock text-success ml-5">
                            {stock} Items In Stock
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <>
                  {product?.primaryLang?.description && (
                    <ProductTab details={product?.primaryLang} />
                  )}
                  {relatedProd?.length > 0 && (
                    <div className="row mt-60">
                      <div className="col-12">
                        <h3 className="section-title style-1 mb-30">
                          Related products
                        </h3>
                      </div>
                      <div className="col-12">
                        <div className="row related-products position-relative">
                          <RelatedSlider />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductDetails

ProductDetails.propTypes = {
  product: PropTypes.any,
}
