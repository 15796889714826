import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import cartSaga from "./cart&order/saga"
import storeItemsSaga from "./store&location/saga"
import productsSaga from "./product/saga"
import layoutGroupSaga from "./group&collections/saga"
import bannerSaga from "./banner/saga"
import classificationSaga from "./classifications/saga"
import siteSettingsSaga from "./settings/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(cartSaga),
    fork(storeItemsSaga),
    fork(productsSaga),
    fork(layoutGroupSaga),
    fork(bannerSaga),
    fork(classificationSaga),
    fork(siteSettingsSaga),
  ])
}
