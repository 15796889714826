import { connect, useDispatch, useSelector } from "react-redux"
import Layout from "../../components/layout/Layout"
import React, { useEffect } from "react"
import CartItemWeb from "components/elements/CartItemWeb"
import { useWindowSize } from "react-use"
import CartItemMob from "components/elements/CartItemMob"
import { getUserCart } from "store/actions"
import TableLoader from "components/Common/loaders/TableLoader"
import SmallCardLoader from "components/Common/loaders/SmallCardLoader"
import usePrice from "constants/UsePrice"
import { Link, useHistory } from "react-router-dom"
import EmptyCartSvg from "components/Common/emptyCart"
import useLangSwitch from "constants/LanguageSwicth"

const Cart = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { userCart, loading, cartItems, specialMessages, shipping } =
    useSelector(state => ({
      userCart: state.Cart.userCart,
      loading: state.Cart.loading,
      cartItems: state.Cart.cartItems,
      specialMessages: state.SiteSettings.siteSettings.specialMessages,
      shipping: state.SiteSettings.siteSettings.shipping,
    }))

  const defaultShippingCharge = shipping?.defaultShippingCharge

  const { width } = useWindowSize()

  useEffect(() => {
    dispatch(getUserCart())
  }, [dispatch, cartItems?.length])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const outOfStock = cartItems?.filter(i => i.isOutOfStock === true)

  const handleCheckout = () => {
    if (outOfStock?.length === 0) {
      history.push("/checkout")
    }
  }

  useEffect(() => {
    console.clear()
  }, [])

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="Cart">
        <section className="mb-50">
          <div className="container p-5 sm:p-0">
            <div className="row sm:p-2">
              <div className="col-lg-8 mb-40">
                <h4 className="heading-2 mb-10">Your Cart</h4>
                {cartItems?.length >= 1 && (
                  <div className="d-flex justify-content-between sm:flex-col">
                    <h6 className="text-body">
                      <p className="text-brand">{language?.deliveryOffer}</p>
                      There are{" "}
                      <span className="text-brand">
                        {cartItems?.length}
                      </span>{" "}
                      products in your cart
                    </h6>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              {cartItems?.length >= 1 ? (
                <>
                  <div className="col-lg-8 mb-4 pb-4">
                    {loading ? (
                      <TableLoader />
                    ) : (
                      <>
                        <div className="table-responsive shopping-summery">
                          {width > 480 ? (
                            <CartItemWeb cartItems={cartItems} />
                          ) : (
                            <div className="p-1">
                              {cartItems?.map((item, i) => (
                                <CartItemMob item={item} key={i} />
                              ))}
                            </div>
                          )}
                        </div>

                        <div className="cart-action text-end">
                          <Link
                            to={"/shop-list"}
                            className="btn bg-transperant text-black border-1-gray rounded-xs"
                          >
                            <i className="fi-rs-shopping-bag mr-10 "></i>
                            Continue Shopping
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-3 col-12 flex-1 mx-4 sm:m-0">
                    {loading ? (
                      <SmallCardLoader />
                    ) : (
                      <div className="">
                        <div className="border p-md-4 p-30 border-radius cart-totals">
                          <div className="heading_s1 mb-3">
                            <h4>Cart Totals</h4>
                            {userCart?.couponCode && (
                              <p className="text-brand">
                                Coupon <span>{userCart?.couponCode}</span> is
                                Applied
                              </p>
                            )}
                          </div>
                          <div className="table-responsive">
                            <table className="table table-border-none">
                              <tbody>
                                <tr className="sm:d-flex">
                                  <td className="cart_total_label ">
                                    Cart Subtotal{" "}
                                  </td>
                                  <td className="cart_total_label sm:w-fit">
                                    :{" "}
                                  </td>

                                  <td className="cart_total_amount">
                                    <span className="font-lg fw-900 text-brand">
                                      {currencyCode} {userCart?.itemsPrice}
                                    </span>
                                  </td>
                                </tr>
                                <tr className="sm:d-flex">
                                  <td className="cart_total_label">
                                    Delivery Charge
                                  </td>
                                  <td className="cart_total_label sm:w-fit">
                                    :
                                  </td>
                                  <td className="cart_total_amount text-brand">
                                    {defaultShippingCharge &&
                                      defaultShippingCharge >
                                        userCart?.shippingPrice && (
                                        <del>{defaultShippingCharge}</del>
                                      )}
                                    &nbsp;
                                    {currencyCode} {userCart?.shippingPrice}
                                  </td>
                                </tr>

                                {userCart?.couponDiscount ? (
                                  <tr className="sm:d-flex">
                                    <td className="cart_total_label">
                                      Coupon Discount
                                    </td>
                                    <td className="cart_total_label sm:w-fit">
                                      :
                                    </td>
                                    <td className="cart_total_amount text-brand">
                                      {currencyCode} {userCart?.couponDiscount}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                <tr className="sm:d-flex">
                                  <td className="cart_total_label">
                                    Gross Total
                                  </td>
                                  <td className="cart_total_label sm:w-fit">
                                    :
                                  </td>

                                  <td className="cart_total_amount">
                                    <strong>
                                      <span className="font-xl fw-900 text-brand">
                                        {currencyCode} {userCart?.totalPrice}
                                      </span>
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex ">
                            <Link
                              to={"#!"}
                              onClick={handleCheckout}
                              className={`btn w-fit m-auto  ${
                                outOfStock?.length >= 1 &&
                                "bg-disable border-none"
                              }`}
                            >
                              <i className="fi-rs-box-alt mr-10"></i>
                              Proceed To CheckOut
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <EmptyCartSvg
                  style={{
                    isDes: true,
                    height: "400px",
                    margin: "auto",
                    width: "fit-content",
                  }}
                />
              )}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  cartItems: state?.cart,
  activeCart: state?.counter,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
