import React, { useState } from "react"
import { connect, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "constants/UsePrice"
import { Link } from "react-router-dom"
import { addOrRemoveFav } from "store/actions"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import { defualtProd } from "assets/imgs/dealsToHome"
import useCloudinary from "constants/UseCloudinaryImage"

const ProductCardTwo = ({ product }) => {
  const dispatch = useDispatch()

  const [wishlistActive, setWishlistActive] = useState(false)

  const handleWishlist = productId => {
    dispatch(addOrRemoveFav(productId))
    setWishlistActive(!wishlistActive)
  }

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, currentVarient, hideProduct } =
    usePrice(product)

  const removeSpace = language?.name?.replace(/ /g, "-")

  const diff = Math.abs(oldPrice - price)
  const discountPercentage = Math.floor((diff / oldPrice) * 100)

  return (
    <>
      {hideProduct === true ? (
        ""
      ) : (
        <div className="product-cart-wrap border-none">
          <div className="product-img-action-wrap">
            <div className="product-img product-img-zoom m-auto ">
              <Link to={`/products/${removeSpace}`}>
                <img
                  className="default-img"
                  src={`${
                    useCloudinary(product.images[0]?.url, "q_auto:eco") ??
                    defualtProd
                  }`}
                  alt=""
                />
                <img
                  className="hover-img"
                  src={
                    useCloudinary(
                      product.images[1]?.url
                        ? product.images[1]?.url
                        : product.images[0]?.url,
                      "q_auto:eco"
                    ) ?? defualtProd
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="product-action-1">
              <Link
                to={`/products/${removeSpace}`}
                aria-label="Quick view"
                className="action-btn hover-up"
                data-bs-toggle="modal"
              >
                <i className="fi-rs-eye"></i>
              </Link>
              <a
                aria-label="Add To Wishlist"
                className="action-btn bg-white"
                onClick={e => handleWishlist(product?._id)}
              >
                {wishlistActive ? (
                  <FaHeart
                    className="text-danger"
                    style={{ marginTop: "-6px" }}
                  />
                ) : (
                  <FaRegHeart
                    className="text-black"
                    style={{ marginTop: "-6px" }}
                  />
                )}
              </a>
            </div>
          </div>
          <div className="product-content-wrap p-10 pt-0">
            <h2 className="text-size-12 mt-1">
              <Link to={`/products/${removeSpace}`}>
                <span>
                  {language?.name?.slice(0, 32)} &nbsp;
                  <span style={{ whiteSpace: "nowrap" }}>
                    ({currentVarient})
                  </span>
                </span>
              </Link>
            </h2>

            <div className="product-price mt-0 p-0">
              {oldPrice && (
                <>
                  <span className="old-price font-normal">{oldPrice}</span>
                  &nbsp;
                </>
              )}
              <span
                className="font-head text-gray"
                style={{ margin: "0 5px 0 5px" }}
              >
                {currencyCode}
                {price}&nbsp;
              </span>
              {oldPrice && (
                <span className="price-per">{discountPercentage}% off</span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProductCardTwo)

ProductCardTwo.propTypes = {
  product: PropTypes.any,
}
