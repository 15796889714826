import React from "react"
import Layout from "components/layout/Layout"
import ProductsList from "./custom-product-grid"

function CustomGrid() {
  return (
    <>
      <Layout parent="Home" sub="Collection" subChild="">
        <section className="mt-10 mb-50">
          <ProductsList />
        </section>
      </Layout>
    </>
  )
}

export default CustomGrid
