import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DEFAULT_STORE,
  GET_PAYMENT_KEY,
  GET_SITE_SETTINGS,
} from "./actionTypes"
import {
  getSiteSettingsSuccess,
  getSiteSettingsFail,
  getPaymentKeySuccess,
  getPaymentKeyFail,
  getDefaultStoreSuccess,
  getDefaultStoreFail,
} from "./actions"
import { get } from "helpers/api_methods"

function getSettingsAPi() {
  return get("/settings/custom/all")
}
function getPaymentKeyAPi() {
  return get("/settings/custom/creditionals")
}
function fetchDefaultStoreIdAPi() {
  return get("/settings/custom/default-store")
}

function* fetchSettings() {
  try {
    const response = yield call(getSettingsAPi)

    yield put(getSiteSettingsSuccess(response))
  } catch (error) {
    yield put(getSiteSettingsFail(error))
  }
}

function* fetchPaymentKey() {
  try {
    const response = yield call(getPaymentKeyAPi)
    yield put(getPaymentKeySuccess(response))
  } catch (error) {
    yield put(getPaymentKeyFail(error))
  }
}

function* fetchDefaultStoreId() {
  try {
    const response = yield call(fetchDefaultStoreIdAPi)
    yield put(getDefaultStoreSuccess(response))
  } catch (error) {
    yield put(getDefaultStoreFail(error))
  }
}
function* siteSettingsSaga() {
  yield takeEvery(GET_SITE_SETTINGS, fetchSettings)
  yield takeEvery(GET_PAYMENT_KEY, fetchPaymentKey)
  yield takeEvery(GET_DEFAULT_STORE, fetchDefaultStoreId)
}

export default siteSettingsSaga
