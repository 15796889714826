import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import UserAddressList from "./userAddressList"
import { useDispatch, useSelector } from "react-redux"
import {
  couponValidate,
  createRazorpayOrder,
  getPaymentKey,
  getUserAddress,
  getUserCart,
  makeUserOrder,
  showToasterAction,
  verifyRazorpayOrder,
} from "store/actions"
import usePrice from "constants/UsePrice"
import useLangSwitch from "constants/LanguageSwicth"
import { Link, useHistory } from "react-router-dom"
import SmallCardLoader from "components/Common/loaders/SmallCardLoader"

import useRazorpay from "react-razorpay"
import { FaSpinner } from "react-icons/fa"
import CheckoutItem from "./checkoutItem"

const Checkout = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const Razorpay = useRazorpay()

  const {
    userCart,
    loading,
    cartItems,
    specialMessages,
    paymentType,
    paymentKey,
    coupon,
    couponLoad,
    orderLoad,
    paymentInfo,
    paymentLoad,
    paymentVerifyInfo,
  } = useSelector(state => ({
    userCart: state.Cart.userCart,
    loading: state.Cart.loading,
    cartItems: state.Cart.cartItems,
    coupon: state.Cart.coupon,
    couponLoad: state.Cart.couponLoad,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    paymentType: state.SiteSettings.siteSettings.paymentType,
    paymentKey: state.SiteSettings.paymentKey.paymentKey,
    orderLoad: state.Cart.orderLoad,
    paymentInfo: state.Cart.paymentInfo,
    paymentLoad: state.Cart.paymentLoad,
    paymentVerifyInfo: state.Cart.paymentVerifyInfo,
  }))

  useEffect(() => {
    if (cartItems?.length <= 0) {
      history.push("/")
    }
  }, [cartItems])

  const [changePaymentType, setChangePaymentType] = useState("Cash on Delivery")
  const [couponText, setCouponText] = useState("")
  const [shippingAddress, setShippingAddress] = useState(null)

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getPaymentKey())
    dispatch(getUserAddress())
  }, [dispatch])

  const { currencyCode } = usePrice()

  const noStock = cartItems?.length <= 0 ? true : false

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handlePaymentInput = () => {
    if (paymentType === "Cash on Delivery") {
      return "cod"
    } else if (paymentType === "Online Payment") {
      return "online"
    } else {
      return "both"
    }
  }

  const handlePaymentChange = value => {
    setChangePaymentType(value)
  }

  const initatePayment = () => {
    const options = {
      key: paymentKey?.keyId,
      amount: paymentInfo?.amount,
      currency: paymentInfo?.currency,
      order_id: paymentInfo?.id,
      handler: async res => {
        try {
          dispatch(verifyRazorpayOrder(res))
        } catch (error) {
          console.log(error)
        }
      },

      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3BB77E",
      },
    }

    const rzpay = new Razorpay(options)
    rzpay.open()
  }

  const handleRazorPayPayment = async () => {
    try {
      dispatch(createRazorpayOrder())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (paymentInfo?.id) {
      initatePayment()
    }
  }, [paymentInfo?.id])

  useEffect(() => {
    if (paymentVerifyInfo?.isVerified) {
      const input = {
        shippingInfo: shippingAddress,
        paymentType: changePaymentType,
        onlinePayment: {
          isVerified: paymentVerifyInfo?.isVerified,
          ...paymentVerifyInfo?.razorpayRes,
        },
      }
      dispatch(makeUserOrder(input, history))
    }
  }, [paymentVerifyInfo?.isVerified])

  const handleSubmitCoupon = e => {
    e.preventDefault()
    dispatch(couponValidate(couponText))
  }

  const handleCodPayment = () => {
    const input = {
      shippingInfo: shippingAddress,
      paymentType: changePaymentType,
    }
    if (shippingAddress === null) {
      dispatch(
        showToasterAction({
          data: {
            title: "Shipping Address",
            message: "Select a Address First",
          },
          type: "default",
        })
      )
    } else {
      dispatch(makeUserOrder(input, history))
    }
  }

  useEffect(() => {
    console.clear()
  }, [])

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="Checkout">
        <section className="mt-50 mb-50">
          <div className="container px-5">
            <div className="row">
              <div className="col-lg-8 mb-40">
                <h1 className="heading-2 mb-10">Checkout</h1>
                <div className="d-flex justify-content-between">
                  <h6 className="text-body">
                    <p className="text-brand">{language?.deliveryOffer}</p>
                    There are{" "}
                    <span className="text-brand">{cartItems?.length}</span>{" "}
                    products in your cart
                  </h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="row mb-50">
                  <div className="mb-sm-15 mb-lg-0 mb-md-3 ">
                    <div className="panel-collapse login_form" id="loginform">
                      <UserAddressList
                        setShippingAddress={setShippingAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="border p-40 cart-totals ml-30 mb-30">
                  <div className="d-flex align-items-end justify-content-between mb-30">
                    <h4>Your Order</h4>
                    <h6 className="text-muted">Subtotal</h6>
                  </div>
                  <div className="divider-2 mb-30"></div>
                  <div className="table-responsive order_table table-border-tr-none max-h:400px">
                    {loading ? (
                      <SmallCardLoader />
                    ) : (
                      <table
                        className={!noStock ? "table no-border" : "d-none"}
                      >
                        <tbody>
                          {cartItems?.map((item, i) => (
                            <CheckoutItem item={item} key={i} />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="p-0 pb-20 ml-30 sm:m-0">
                  <form className="apply-coupon" onSubmit={handleSubmitCoupon}>
                    <input
                      type="text"
                      placeholder="Enter Coupon Code..."
                      onChange={e => setCouponText(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-md white-space-nowrap"
                    >
                      {couponLoad ? (
                        <FaSpinner className="spinner" />
                      ) : (
                        "Apply Coupon"
                      )}
                    </button>
                  </form>

                  {coupon?.response?.message && (
                    <p className="mx-2 pt-2 text-danger">
                      {coupon?.response?.message}
                    </p>
                  )}
                  {typeof coupon?.response === "number" && (
                    <p className="mx-2 pt-2 text-green">{coupon?.success}</p>
                  )}
                </div>
                {loading ? (
                  <></>
                ) : (
                  <div className="border p-40 cart-totals ml-30 mb-50  ">
                    <div className="payment_method">
                      <div className="mb-10">
                        <h5>Order summary</h5>
                      </div>
                      <div className="mb-30">
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Sub total</p>
                          <p>
                            {currencyCode} {userCart?.itemsPrice}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Delivery charge</p>
                          <p>
                            {currencyCode} {userCart?.shippingPrice}
                          </p>
                        </div>
                        {userCart?.couponDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Coupon Discount</p>
                            <p>
                              {currencyCode} {userCart?.couponDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {userCart?.totalDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Total Discount</p>
                            <p>
                              {currencyCode} {userCart?.totalDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}{" "}
                        {/* {userCart?.totalPrimeDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Prime Discount</p>
                            <p>
                              {currencyCode} {userCart?.totalPrimeDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )} */}
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-20">
                          <h6>Gross Total</h6>
                          <h6>
                            {currencyCode} {userCart?.totalPrice}
                          </h6>
                        </div>
                      </div>
                      <div className="mb-10">
                        <h5 className="pb-1 sm:mb-2">Payment</h5>
                      </div>
                      <div className="d-flex align-items-end sm:flex-col">
                        <div className="payment_option sm:text-start">
                          {handlePaymentInput() === "both" && (
                            <>
                              {CashOnDeliveryInput()}
                              {OnlinePaymentInput()}
                            </>
                          )}
                          {handlePaymentInput() === "cod" &&
                            CashOnDeliveryInput()}
                          {handlePaymentInput() === "online" &&
                            OnlinePaymentInput()}
                        </div>

                        {changePaymentType === "Online Payment" ? (
                          <Link
                            to="#!"
                            className={`btn btn-fill-out btn-block ml-auto ${
                              noStock &&
                              "bg-disable border-none cursor-notAllowed"
                            }`}
                            onClick={() => handleRazorPayPayment()}
                          >
                            Pay
                            {paymentLoad && <FaSpinner className="spinner" />}
                          </Link>
                        ) : (
                          <Link
                            to="#!"
                            onClick={() => handleCodPayment()}
                            className={`btn btn-fill-out btn-block ml-auto sm:mt-4 ${
                              noStock &&
                              "bg-disable border-none cursor-notAllowed"
                            }`}
                          >
                            {orderLoad && (
                              <FaSpinner className="spinner mx-1" />
                            )}
                            Place Order
                          </Link>
                        )}
                      </div>
                      {/* {order?.message && (
                        <p className="text-danger">
                          {order?.message} Remove Stock Out Item first!
                        </p>
                      )} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )

  function CashOnDeliveryInput() {
    return (
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="payment_option"
          id="exampleRadiosCod"
          value={"Cash on Delivery"}
          checked={changePaymentType === "Cash on Delivery"}
          onChange={e => handlePaymentChange(e.target.value)}
        />
        <label className="form-check-label" htmlFor="exampleRadiosCod">
          Cash on Delivery
        </label>
        {changePaymentType === "Cash on Delivery" && (
          <div className="form-group">
            <p className="text-muted mt-5">Select a Address and place order</p>
          </div>
        )}
      </div>
    )
  }

  function OnlinePaymentInput() {
    return (
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="Online Payment"
          value={"Online Payment"}
          id="exampleRadiosOnline"
          checked={changePaymentType === "Online Payment"}
          onChange={e => handlePaymentChange(e.target.value)}
        />
        <label className="form-check-label" htmlFor="exampleRadiosOnline">
          Online Payment
        </label>
        {changePaymentType === "Online Payment" && (
          <div className="form-group ">
            <p className="text-muted mt-5">
              You can pay with your Debit / Credit Cards or using UPI
              <br />
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default Checkout
