import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getUser } from "store/actions"

function DashBoard({ activeIndex, handleOnClick }) {
  const dispatch = useDispatch()

  const { user } = useSelector(state => ({
    user: state.Login.user,
  }))

  useEffect(() => {
    dispatch(getUser())
  }, [])

  return (
    <div
      className={
        activeIndex === 1 ? "tab-pane fade active show" : "tab-pane fade "
      }
    >
      <div className="card md:mt-50">
        <div className="card-header">
          <h3 className="mb-0 ">
            Hello!{" "}
            <span className="text-brand">{user?.name ? user?.name : ""}</span>
          </h3>
        </div>
        <div className="card-body">
          <p>
            From your account dashboard. you can easily check &amp; view your{" "}
            <Link to="#!" onClick={() => handleOnClick(2)}>
              recent orders
            </Link>
            ,
            <br />
            manage your{" "}
            <Link to="#!" onClick={() => handleOnClick(4)}>
              shipping and billing addresses
            </Link>{" "}
            and{" "}
            <Link to="#!" onClick={() => handleOnClick(5)}>
              edit your account details.
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default DashBoard

DashBoard.propTypes = {
  activeIndex: PropTypes.any,
  handleOnClick: PropTypes.func,
}
