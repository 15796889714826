// Dashboard
import Pages500 from "pages/404/pages-500"
import Pages404 from "pages/404/pages-404"
import ProductSingleView from "pages/product"
import Cart from "pages/shop/shop-cart"
import ProductsList from "pages/shop/shop-list-left"
import Account from "pages/user/page-account"
import Contact from "pages/user/page-contact"
import WishlistModal from "pages/shop/shop-wishlist"
import About from "pages/static/page-about"
import StaticPage from "pages/static/StaticPage"
import Checkout from "pages/checkout/checkout"
import OrderSuccess from "pages/checkout/OrderSuccess"
import CustomGrid from "pages/custom/index"
import shopQuickPurchase from "pages/shop/shop-quickPurchase"
import AllCategoryList from "pages/category"
import OrderDetails from "pages/user/order/orderDetails"
import AllColllection from "pages/custom/collections"
import Home from "pages/Home/home1"

const publicRoutes = [
  //defualt routes
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
  { path: "/", component: Home },
  //main routes
  { path: "/custom/:type", component: CustomGrid },
  { path: "/custom/:type/:name", component: CustomGrid },
  { path: "/products/:name", component: ProductSingleView },
  { path: "/categroies", component: AllCategoryList },
  { path: "/collections", component: AllColllection },
  { path: "/shop-list", component: ProductsList },

  //static routes
  { path: "/contact", component: Contact },
  { path: "/about", component: About },

  { path: "/privacy-policy", component: StaticPage },
  { path: "/return-policy", component: StaticPage },
  { path: "/terms", component: StaticPage },
]

const AuthProtectedRoute = [
  { path: "/cart", component: Cart },
  { path: "/wishlist", component: WishlistModal },
  { path: "/quick-purchase", component: shopQuickPurchase },
  { path: "/user-account", component: Account },
  { path: "/user/order/:id", component: OrderDetails },
  { path: "/checkout", component: Checkout },
  { path: "/order-success", component: OrderSuccess },
]

export { publicRoutes, AuthProtectedRoute }
