import { connect, useSelector } from "react-redux"
import React from "react"

import { Link, useHistory, useLocation } from "react-router-dom"
import { map } from "lodash"
import PropTypes from "prop-types"

import category1 from "assets/imgs/theme/icons/category-1.svg"
import { useLangSwitchquery } from "constants/LanguageSwicth"
import CheckboxFilter from "./CheckboxFilter"
import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import useCloudinary from "constants/UseCloudinaryImage"

const CategoryProduct = ({ className, showCount, handleToggle }) => {
  //hooks
  const history = useHistory()
  const location = useLocation()
  const language = useLangSwitchquery()

  //state
  const query = location?.query

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))

  const handleCheckBox = (e, category) => {
    e.preventDefault()
    if (category === "all") {
      history.push({
        pathname:
          location?.pathname === "/" ? "/shop-list" : location?.pathname,
        query: {
          ...location.query,
          cat: "",
          prod: category,
          isProduct: true,
          coll: "",
        },
      })
    } else {
      history.push({
        pathname:
          location?.pathname === "/" ? "/shop-list" : location?.pathname,
        query: {
          ...location.query,
          cat: category?.replace(/ /g, "-"),
          prod: "",
          isProduct: false,
          coll: "",
          subCat: false,
        },
      })
    }
  }

  const handleSingleViewClick = catName => {
    const removeSpace = catName?.replace(/ /g, "-")

    if (catName === "all") {
      history.push({
        pathname: `/categroies`,
      })
    } else {
      history.push({
        pathname: `/custom/category/${removeSpace ? removeSpace : ""}`,
        state: "category",
        cat: removeSpace,
      })
    }

    handleToggle()
  }

  return (
    <>
      {loading ? (
        <>
          <CustomizedLoader
            count={3}
            containerClass={"flex-col"}
            style={{ height: "20px" }}
            imageStyle={{ height: "30px", width: "30px" }}
            type={"withImg"}
          />
        </>
      ) : (
        <ul className={className + " scroll-300"}>
          <li onClick={() => handleSingleViewClick("all")}>
            <Link to={"#!"} style={{ fontWeight: "500" }}>
              <img src={category1} alt="" />
              All
            </Link>
            {showCount === false ? (
              <></>
            ) : (
              <span>
                <div style={{ height: "22px" }}>
                  <input
                    type="checkbox"
                    className="form-check-input cursor-pointer"
                    name={"all"}
                    value={"all"}
                    checked={query?.prod === "all"}
                    id={"all"}
                    onChange={e => handleCheckBox(e, "all")}
                  />
                </div>
              </span>
            )}
          </li>
          {map(allCategories, (cat, key) => (
            <li
              key={key}
              onClick={() => handleSingleViewClick(cat.primaryLang?.name)}
            >
              <Link to="#!" style={{ fontWeight: "500" }}>
                <img
                  src={
                    cat?.logoImages[0]?.url
                      ? useCloudinary(cat?.logoImages[0]?.url, "c_thumb,w_100")
                      : category1
                  }
                  alt=""
                />
                {language === "Primary"
                  ? cat.primaryLang?.name
                  : cat.secondaryLang?.name}
              </Link>
              {showCount === false ? (
                <></>
              ) : (
                <span>
                  <CheckboxFilter
                    data={cat}
                    query={query}
                    handleCheckBox={handleCheckBox}
                  />
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default connect(null, {})(CategoryProduct)

CategoryProduct.propTypes = {
  className: PropTypes.string,
  showCount: PropTypes.bool,
  handleToggle: PropTypes.any,
}
