import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import OrderContent from "pages/checkout/OrderContent"
import TrackOrder from "./trackOrder"
import { BsArrowLeft } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { cancelOrder, getOrderDetails } from "store/actions"
import moment from "moment"
import { Link } from "react-router-dom"
import download from "assets/imgs/theme/icons/icon-download.svg"
import { exportComponentAsJPEG } from "react-component-export-image"
import ReturnOrderPopup from "components/elements/ReturnOrderPopup"
import { useWindowSize } from "react-use"
import { LogoFull } from "assets/imgs/dealsToHome"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { FaSpinner } from "react-icons/fa"

function OrderDetails({ activeIndex, orderId, handleOnClick }) {
  const dispatch = useDispatch()
  const orderDetailCompRef = useRef()
  const { width } = useWindowSize()

  const { order, orderLoad } = useSelector(state => ({
    order: state.Cart.order,
    orderLoad: state.Cart.orderLoad,
  }))

  const orderItems = order?.orderItems
  const [orderTrack, setOrderTrack] = useState(false)

  useEffect(() => {
    dispatch(getOrderDetails(orderId))
  }, [dispatch])

  const [isShow, setIsShow] = useState({ status: false })

  const address = order?.shippingInfo

  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [isProcessing, setIsProcessing] = useState()

  useEffect(() => {
    const filteredItem = orderItems?.filter(
      i => i.orderItemStatus === "Processing"
    )
    const ids = filteredItem?.map(i => i._id)

    setSelectedItemIds(ids)

    if (filteredItem?.length >= 1) {
      setIsProcessing(true)
    } else {
      setIsProcessing(false)
    }
  }, [order?.orderItems])

  const handleCancelOrder = () => {
    if (selectedItemIds?.length >= 1) {
      const input = {
        id: order?._id,
        cancelOrderItems: selectedItemIds,
      }
      dispatch(cancelOrder(input))
    }
  }

  const handleReturnOrder = () => {
    setIsShow({ ...isShow, status: true })
  }

  return (
    <>
      <ReturnOrderPopup isShow={isShow?.status} setIsShow={setIsShow} />

      <div
        className={
          activeIndex === 3 ? "tab-pane fade active show" : "tab-pane fade "
        }
      >
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between ">
            <h3 className="mb-0">
              <BsArrowLeft
                className="mx-1 cursor-pointer"
                onClick={() => handleOnClick(2)}
              />
              Order Summary
            </h3>
            <a className={""}>
              <div className="w-100 d-flex align-item-center justify-content-end">
                {order?.orderStatus === "Processing" && isProcessing === true && (
                  <Link
                    to={"#!"}
                    id="invoice_download_btn"
                    onClick={() => handleCancelOrder()}
                    className="btn btn-lg btn-custom bg-light text-black hover-up border-1-gray"
                  >
                    Cancel
                    {orderLoad && <FaSpinner className="mx-2 spinner" />}
                  </Link>
                )}
                {order?.orderStatus === "Delivered" && (
                  <Link
                    to={"#!"}
                    id="invoice_download_btn"
                    onClick={() => handleReturnOrder()}
                    className="btn btn-lg btn-custom bg-light text-black hover-up border-1-gray"
                  >
                    Return
                    {orderLoad && <FaSpinner className="mx-2 spinner" />}
                  </Link>
                )}
              </div>
            </a>
          </div>
          <div className="card-body">
            <div className="px-4">
              <span className="d-flex align-items-center mb-1">
                <h5 className="text-brand" style={{ fontWeight: "600" }}>
                  {order?.orderStatus}
                </h5>
              </span>
              <h6
                className="text-secondary mb-30 underline"
                onClick={() => setOrderTrack(!orderTrack)}
              >
                Track your order
                <span className="mx-2">
                  {orderTrack === true ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </h6>
              {orderTrack && (
                <div>
                  {order?.orderStatus === "Returned" ||
                  order?.orderStatus === "Cancelled" ? (
                    <></>
                  ) : (
                    <TrackOrder />
                  )}
                </div>
              )}
            </div>

            <div className="w-100 d-flex align-item-center justify-content-end mb-20 border-b-4 pb-4">
              {/* {order?.orderStatus === "Processing" && isProcessing === true && (
                <Link
                  to={"#!"}
                  id="invoice_download_btn"
                  onClick={() => handleCancelOrder()}
                  className="btn btn-lg btn-custom bg-light text-black hover-up border-1-gray"
                >
                  Cancel
                </Link>
              )}
              {order?.orderStatus === "Delivered" && (
                <Link
                  to={"#!"}
                  id="invoice_download_btn"
                  onClick={() => handleReturnOrder()}
                  className="btn btn-lg btn-custom bg-light text-black hover-up border-1-gray"
                >
                  Return
                </Link>
              )} */}
            </div>

            <div ref={orderDetailCompRef} className="p-1.5">
              <div className="h:40px w:100px mb-3 ms-auto">
                <img src={LogoFull} alt="logo" className="h-100 w-100" />
              </div>
              <div className="d-flex align-items-start justify-content-between mb-30 sm:flex-col">
                {" "}
                <div className="text sm:mb-10">
                  <h6 className="mb-5">Delivary Address</h6>
                  <strong className="text-brand text-size-20">
                    {address?.name}
                  </strong>{" "}
                  <p>{address?.contactNumber}</p>
                  <p>{address?.email}</p>
                  {address?.address}
                </div>
                <div className="text-end">
                  <h6>
                    Order ID:{" "}
                    <span className="text-brand">{order?.orderId}</span>
                  </h6>
                  <p>
                    {" "}
                    <span className="">
                      {" "}
                      {moment(order?.createdAt).format("DD MMM, YYYY LT")}
                    </span>
                  </p>
                </div>
              </div>
              {order?.orderId && (
                <OrderContent
                  isNavbar={false}
                  isFooter={false}
                  isAccount={true}
                />
              )}
            </div>

            {width > 768 && (
              <div
                className={
                  "invoice-btn-section clearfix d-print-none d-flex align-items-center sm:align-items-end justify-content-end sm:flex-col"
                }
              >
                <Link
                  to={"#!"}
                  id="invoice_download_btn"
                  onClick={() => exportComponentAsJPEG(orderDetailCompRef)}
                  className="btn btn-lg btn-custom bg-info  btn-download hover-up"
                >
                  {" "}
                  <img src={download} alt="" /> Download
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderDetails
OrderDetails.propTypes = {
  activeIndex: PropTypes.any,
  orderId: PropTypes.any,
  handleOnClick: PropTypes.func,
}
