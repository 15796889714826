import CollectionList from "components/ecommerce/CollectionList"
import React from "react"
import PropTypes from "prop-types"
import { useBanner } from "./useBannerData"

function CollectionG1({ groupDetail, col }) {
  const banner = useBanner(
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <section className="section-padding">
      <div className="container">
        <div
          className="section-title mb-4 wow animate__animated animate__fadeIn"
          data-wow-delay="0"
        >
          {!col && (
            <h3 className="mb-0 pb-0 gp_head">
              {groupDetail?.primaryLang?.title}
            </h3>
          )}
        </div>
        <div className="row">
          <CollectionList
            listData={banner}
            type={groupDetail?.type}
            col={col}
          />
        </div>
      </div>
    </section>
  )
}

export default CollectionG1

CollectionG1.propTypes = {
  groupDetail: PropTypes.object,
  col: PropTypes.number,
}
