import { connect, useSelector } from "react-redux"
import React from "react"
import SwiperCore, { Navigation, Thumbs } from "swiper"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import { map } from "lodash"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"

import category1 from "assets/imgs/theme/icons/category-1.svg"
import { useLangSwitchquery } from "constants/LanguageSwicth"
import RotatingGallery from "components/Common/loaders/RotatingGallery"
import useCloudinary from "constants/UseCloudinaryImage"

SwiperCore.use([Navigation, Thumbs])

const CategoryProduct = ({ className, showCount }) => {
  //hooks
  const history = useHistory()
  const location = useLocation()
  const language = useLangSwitchquery()
  const params = useParams()

  //state
  const query = location?.query

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))

  const handleCheckBox = (e, category) => {
    e.preventDefault()
    if (category === "all") {
      history.push({
        pathname: location?.pathname ?? "/shop-list",
        query: {
          ...location.query,
          cat: "",
          prod: category,
          isProduct: true,
          coll: "",
        },
      })
    } else {
      if (params?.type === "category") {
        history.push({
          pathname: `/custom/category/${category?.replace(/ /g, "-")}`,
          query: {
            ...location.query,
            cat: category?.replace(/ /g, "-"),
            prod: "",
            isProduct: false,
            coll: "",
            subCat: false,
          },
        })
      } else {
        history.push({
          pathname: location?.pathname ?? "/shop-list",
          query: {
            ...location.query,
            cat: category?.replace(/ /g, "-"),
            prod: "",
            isProduct: false,
            coll: "",
            subCat: false,
          },
        })
      }
    }
  }

  console.log("data :", query?.prod)

  return (
    <>
      {loading ? (
        <>
          <RotatingGallery />
        </>
      ) : (
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          freeMode={true}
          slidesPerView={"auto"}
          navigation={true}
          className="mySwiper2 myNewArrow"
        >
          <SwiperSlide
            onClick={e => handleCheckBox(e, "all")}
            style={{ width: "fit-content" }}
          >
            <ul className={className}>
              <li className={query?.prod === "all" ? "active_tag" : ""}>
                <Link to={"#!"}>
                  <img
                    src={category1}
                    className="fit-contain"
                    height="100%"
                    width={"100%"}
                  />
                  All
                </Link>
              </li>
            </ul>
          </SwiperSlide>

          {map(allCategories, (cat, key) => (
            <SwiperSlide
              key={key}
              onClick={e => handleCheckBox(e, cat.primaryLang?.name)}
              style={{ width: "fit-content" }}
            >
              <ul className={className}>
                <li
                  className={`${
                    query?.cat?.replace(/-/g, " ") === cat.primaryLang?.name
                      ? "active_tag"
                      : ""
                  } `}
                >
                  <Link to={"#!"}>
                    <img
                      src={
                        cat?.logoImages[0]?.url
                          ? useCloudinary(
                              cat?.logoImages[0]?.url,
                              "c_thumb,w_100"
                            )
                          : category1
                      }
                      className="fit-contain"
                      height="100%"
                      width={"100%"}
                    />
                    {language === "Primary"
                      ? cat.primaryLang?.name
                      : cat.secondaryLang?.name}
                  </Link>
                </li>
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
}

export default connect(null, {})(CategoryProduct)

CategoryProduct.propTypes = {
  className: PropTypes.string,
  showCount: PropTypes.bool,
}
