import SubCategoryList from "components/ecommerce/filter/subCategoryList"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import CategoryProduct2 from "components/ecommerce/filter/CategoryProduct2"

function ShopFilterBar({ sideBarShow, setSideBarShow }) {
  const history = useHistory()
  const location = useLocation()

  const query = location?.query

  const [finalInput, setFinalInput] = useState("")

  useEffect(() => {
    history.push({
      pathname: "/shop-list",
      query: { ...location?.query, search: finalInput },
    })
  }, [finalInput])

  const printValue = debounce(value => setFinalInput(value), 500)

  const handleChangeSearch = e => {
    printValue(e.target.value)
  }

  return (
    <div
      className={
        "col-12 primary-sidebar sticky-sidebar "
        // `${sideBarShow ? "custom-filter-bar" : "display-md-none"}`
      }
    >
      {/* <div className="d-flex justify-between align-items-center">
        <h4 className="text-center ">Filters</h4>
        <p className="display-md-block">
          <BsPlusLg
            onClick={() => setSideBarShow(false)}
            style={{
              fontSize: "1.3rem",
              cursor: "pointer",
              transform: "rotate(45deg)",
              color: "black",
            }}
          />
        </p>
      </div> */}

      {/* <div className="sidebar-widget-2 widget_search mb-30">
        <div className="search-form">
          <form action="#">
            <input
              type="text"
              onChange={handleChangeSearch}
              placeholder="Search…"
            />
            <button type="submit">
              <i className="fi-rs-search"></i>
            </button>
          </form>
        </div>
      </div> */}

      {/* <div className="text-start mb-30">
        <Tags2 tags={query} />
      </div> */}
      {/* 
      <div className="sidebar-widget  range mb-30">
        <h5 className="section-title style-1 mb-30">Fill by price</h5>

        <div className="price-filter">
          <PriceRangeSlider />
        </div>
      </div> */}

      <div className="sidebar-widget widget-category-2 mb-10 custom_category_label p-0 pt-3">
        {/* <h5 className="section-title style-1 mb-30">Category</h5> */}
        <CategoryProduct2 showCount={false} />
      </div>

      <div className="sidebar-widget widget-category-2 mb-30 custom_category_label p-0 pt-3">
        {/* <h5 className="section-title style-1 mb-30">Sub Category</h5> */}
        <SubCategoryList />
      </div>
      {/*
      <MainCatAndSubCatList />

      <div className="banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none">
        <img src={banner11} alt="" />
        <div className="banner-text">
          <span>Oganic</span>
          <h4>
            Save 17% <br />
            on <span className="text-brand">Oganic</span>
            <br />
            Juice
          </h4>
        </div>
      </div> */}
    </div>
  )
}

export default ShopFilterBar

ShopFilterBar.propTypes = {
  sideBarShow: PropTypes.any,
  setSideBarShow: PropTypes.any,
}
