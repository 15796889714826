import CategoryTab from "components/ecommerce/categoryTab"
import React from "react"
import PropTypes from "prop-types"
import { useBanner } from "./useBannerData"
import useCloudinary from "constants/UseCloudinaryImage"

function ProductG5({ groupDetail }) {
  const product = groupDetail?.products

  const handleBannerData = useBanner(
    // groupDetail?.singleCollection,
    // groupDetail?.singleCategory,
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12">
          <div className="section-title mb-4 mt-4 style-2 wow animate__animated animate__fadeIn ">
            <h3 className="gp_head">{groupDetail?.primaryLang?.title}</h3>
          </div>
          <CategoryTab
            type={groupDetail?.type}
            products={product}
            handleBannerData={handleBannerData[0]}
            bannerImg={useCloudinary(
              groupDetail?.images[0]?.url ?? "",
              "w_1200"
            )}
          />
        </div>
      </div>
    </section>
  )
}

export default ProductG5

ProductG5.propTypes = {
  groupDetail: PropTypes.object,
}
