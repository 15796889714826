import React, { useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "constants/UsePrice"
import { FaHeart, FaRegHeart, FaSpinner } from "react-icons/fa"
import useCartActions from "constants/useCartAction"
import {
  addOrRemoveFav,
  showAreasListAction,
  showLoginPopupAction,
  showToasterAction,
} from "store/actions"
import moment from "moment"
import getDifferenceInDays from "constants/getDifferenceInDays"
import { defualtProd } from "assets/imgs/dealsToHome"
import { BsExclamationLg } from "react-icons/bs"
import useCloudinary from "constants/UseCloudinaryImage"

const ProductCardOne = ({ product, className }) => {
  const dispatch = useDispatch()
  const token = localStorage.getItem("token_grocery")

  const { loading, cartItems, isDefaultStore } = useSelector(state => ({
    loading: state.Cart.addOrRemoveLoad,
    cartItems: state.Cart.cartItems,
    isDefaultStore: state.NearbyStore.store.isDefault,
  }))

  const [productId, setProductId] = useState("")

  const handleCart = product => {
    setProductId(product?._id)
    const options = {
      product: product?._id,
      varient: product?.varients[0]?._id,
      operation: "add",
    }
    if (!token) {
      dispatch(showLoginPopupAction())
    } else if (isDefaultStore === true) {
      dispatch(
        showToasterAction({
          data: {
            title: "Change your location",
            message: "this is defualt store, change your location",
          },
          type: "default",
        })
      )
    } else if (stock >= 1) {
      useCartActions(options, dispatch, cartItems)
    }
  }

  const [wishlistActive, setWishlistActive] = useState(false)

  const handleWishlist = productId => {
    dispatch(addOrRemoveFav(productId))
    setWishlistActive(!wishlistActive)
  }

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, stock, currentVarient, hideProduct } =
    usePrice(product)

  const handleAvailableList = () => {
    dispatch(showAreasListAction())
  }

  // createdAt
  const createdAt = moment(product?.createdAt).format("MM/DD/YYYY")
  const today = moment(new Date()).format("MM/DD/YYYY")

  const diffInDays = getDifferenceInDays(createdAt, today)

  const removeSpace = language?.name?.replace(/ /g, "-")

  const diff = Math.abs(oldPrice - price)

  const discountPercentage = Math.floor((diff / oldPrice) * 100)

  return (
    <>
      {hideProduct === true ? (
        ""
      ) : (
        <div className={`product-cart-wrap shoplist-child mb-30 ${className}`}>
          <div className="product-img-action-wrap shoplist flex-1 position-relative">
            {stock <= 0 && (
              <span
                className="text-danger mx-3 mt-1 position-absolute top-0 right-0 "
                style={{ zIndex: 9 }}
              >
                ! Out Of Stock
              </span>
            )}
            <div className="product-img product-img-zoom">
              <Link to={`/products/${removeSpace}`}>
                <img
                  className="default-img"
                  src={product.images[0]?.url}
                  // src={
                  //   useCloudinary(product.images[0]?.url, "q_auto:eco") ??
                  //   defualtProd
                  // }
                  alt=""
                />
                <img
                  className="hover-img"
                  src={
                    useCloudinary(
                      product.images[1]?.url
                        ? product.images[1]?.url
                        : product.images[0]?.url,
                      "q_auto:eco"
                    ) ?? defualtProd
                  }
                  alt=""
                />
              </Link>
            </div>

            <div className="product-action-1">
              <Link
                to={`/products/${removeSpace}`}
                aria-label="Quick view"
                className="action-btn hover-up"
              >
                <i className="fi-rs-eye"></i>
              </Link>
              <a
                aria-label="Add To Wishlist"
                className="action-btn hover-up"
                onClick={e => handleWishlist(product?._id)}
              >
                {wishlistActive ? (
                  <FaHeart
                    className="text-danger"
                    style={{ marginTop: "-6px" }}
                  />
                ) : (
                  <FaRegHeart
                    className="text-black"
                    style={{ marginTop: "-6px" }}
                  />
                )}
              </a>
            </div>

            <div className="product-badges product-badges-position product-badges-mrg">
              {product?.isFeatured && <span className="hot curvy">Hot</span>}
              {diffInDays <= 2 && <span className="new curvy">New</span>}
              {product?.totalSell > 100 && (
                <span className="best curvy">Best Sell</span>
              )}
              {/* {!product?.discount?.isActive && (
              <span className="sale curvy">Sale</span>
            )} */}
            </div>
            <div className="product-card-bottom mt-0 position-absolute right-10 zIndex-full bottom-0">
              <div className="add-cart">
                <a
                  className={
                    "add " +
                    `${
                      (isDefaultStore || !token || stock === 0) &&
                      "bg-gray text-white opacity-half cursor-notAllowed"
                    }`
                  }
                  onClick={() => handleCart(product)}
                >
                  <i className="fi-rs-shopping-cart mr-5"></i> Add
                  {loading && productId === product?._id && (
                    <FaSpinner className="mx-2 spinner" />
                  )}
                </a>
              </div>
            </div>
          </div>
          <div className="product-content-wrap pt-1 w-100">
            {/* <div className="product-category">
            <Link to="/products">
              <span>{product.brand}</span>
            </Link>
          </div> */}
            <h2>
              <Link
                to={`/products/${removeSpace}`}
                className="font-normal"
                style={{ fontWeight: "normal" }}
              >
                <span>
                  {language?.name?.slice(0, 32)} &nbsp;{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    ({currentVarient})
                  </span>{" "}
                </span>
              </Link>
            </h2>

            {/* <div className="product-rate-cover">
            <div className="product-rate d-inline-block">
              <div className="product-rating" style={{ width: "90%" }}></div>
            </div>
            <span className="font-small ml-5 text-muted">
              {" "}
              ({product.ratingScore})
            </span>
          </div> */}

            {/* <div>
            <span className="font-small text-muted">
              By{" "}
              <Link to="/vendor/1">
                <span>NestFood</span>
              </Link>
            </span>
          </div> */}

            <div className="product-card-bottom mt-0">
              <div className="product-price">
                {oldPrice && (
                  <>
                    <span className="old-price font-normal">{oldPrice}</span>
                    &nbsp;
                  </>
                )}
                <span
                  className="font-head text-gray"
                  style={{ margin: "0 5px 0 5px" }}
                >
                  {currencyCode}
                  {price}&nbsp;
                </span>
                {oldPrice && (
                  <span className="price-per">{discountPercentage}% off</span>
                )}
              </div>
            </div>
            <div>
              {isDefaultStore && (
                <>
                  <p
                    className="m-0 p-0 text-black "
                    style={{ fontSize: "14px" }}
                  >
                    <BsExclamationLg
                      size={"9px"}
                      color="red"
                      className="shake"
                    />
                    not available in your area
                    {/* in your Area */}
                  </p>
                  <p>
                    <Link to={"#"} onClick={() => handleAvailableList()}>
                      view available areas
                    </Link>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProductCardOne)

ProductCardOne.propTypes = {
  product: PropTypes.any,
  className: PropTypes.string,
}
