import { connect, useDispatch, useSelector } from "react-redux"
import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import WishlistItemWeb from "components/elements/WishlistItemWeb"
import CartItemMob from "components/elements/CartItemMob"
import { useWindowSize } from "react-use"
import { getProducts, getQuicklist } from "store/actions"
import TableLoader from "components/Common/loaders/TableLoader"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import { map } from "lodash"
import HeadBodyGrid from "components/Common/loaders/gridLoad"
import Pagination from "components/ecommerce/Pagination"

const QuickPurchase = () => {
  const dispatch = useDispatch()

  const {
    quickPurchase,
    loading,
    currentStore,
    products,
    prodLoading,
    defaultStore,
  } = useSelector(state => ({
    quickPurchase: state.Cart.quickPurchase,
    loading: state.Cart.quickPurchaseLoad,
    currentStore: state.NearbyStore.store,
    products: state.Products.products,
    prodLoading: state.Products.loading,
    defaultStore: state.SiteSettings.defaultStore,
  }))

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  const { width } = useWindowSize()

  //pagination
  const totalList = quickPurchase?.total

  let [pagination, setPagination] = useState([])
  const [limit, setLimit] = useState(10)

  let [pages, setPages] = useState(Math.ceil(totalList / limit))
  let [currentPage, setCurrentPage] = useState(1)

  const showPagination = pages && pages

  const cratePagination = () => {
    let arr = new Array(Math.ceil(totalList / limit))
      .fill()
      .map((_, idx) => idx + 1)

    setPagination(arr)
    setPages(Math.ceil(totalList / limit))
  }

  useEffect(() => {
    if (totalList) {
      cratePagination()
    }
  }, [limit, pages, totalList])

  const next = () => {
    setCurrentPage(page => page + 1)
  }

  const prev = () => {
    setCurrentPage(page => page - 1)
  }

  const handleActive = item => {
    setCurrentPage(item)
  }

  let start = Math.floor((currentPage - 1) / showPagination) * showPagination
  let end = start + showPagination
  const getPaginationGroup = pagination.slice(start, end)

  useEffect(() => {
    dispatch(getQuicklist(currentPage, limit))
  }, [dispatch, currentPage, limit])

  useEffect(() => {
    const options = {
      searchText: "",
      page: 1,
      custom: "all",
      sort: "",
      limit: 10,
      store: currentStoreId,
    }

    dispatch(getProducts(options))
  }, [dispatch, currentStoreId])

  const RndomProduct = () => {
    const num1 = Math.floor(Math.random() * 4) + 1
    const num2 = num1 + 5

    const product = products?.products?.slice(num1, num2)
    return product?.length <= 0 ? products?.products?.slice(0, 4) : product
  }

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="Quick list">
        <section className="mt-30 mb-50 p-30 pt-0">
          <div className="container">
            <div className="row sm:p-2">
              <div className="col-lg-8 mb-40">
                <h4 className="heading-2 mb-10">Quick List</h4>
                {quickPurchase?.total > 0 && (
                  <div className="d-flex justify-content-between sm:flex-col">
                    <h6 className="text-body">
                      There are
                      <span className="text-brand">
                        &nbsp;{quickPurchase?.total}
                      </span>{" "}
                      products in your Quick List
                    </h6>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  <div className="col-lg-8">
                    {quickPurchase?.total > 0 ? (
                      <>
                        {width > 480 ? (
                          <WishlistItemWeb
                            wishlist={quickPurchase?.quickListItems}
                            isQuickList={true}
                          />
                        ) : (
                          map(quickPurchase?.quickListItems, (item, key) => (
                            <CartItemMob item={item} key={key} isWish={true} />
                          ))
                        )}
                      </>
                    ) : (
                      <h4 className="mb-0">No Products</h4>
                    )}
                  </div>

                  <div className="col-lg-4 ml-auto d-flex flex-wrap">
                    {prodLoading ? (
                      <HeadBodyGrid />
                    ) : (
                      RndomProduct()?.length >= 1 && (
                        <>
                          <h5 className="mb-3 w-100 text-gray">You may like</h5>
                          <div className="d-flex flex-wrap">
                            {map(RndomProduct(), (product, key) => (
                              <div
                                className="mb-md-0 mx-1 sm:mx-0.5 sm:max-w:160px  max-w:200px wow animate__animated animate__fadeInUp"
                                data-wow-delay="0"
                                key={key}
                              >
                                <ProductCardOne product={product} />
                              </div>
                            ))}
                          </div>
                        </>
                      )
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
              <nav aria-label="Page navigation example">
                <Pagination
                  getPaginationGroup={getPaginationGroup}
                  currentPage={currentPage}
                  pages={pages}
                  next={next}
                  prev={prev}
                  handleActive={handleActive}
                />
              </nav>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  wishlist: state.wishlist,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(QuickPurchase)

QuickPurchase.propTypes = {
  wishlist: PropTypes.any,
  clearWishlist: PropTypes.any,
  closeWishlistModal: PropTypes.any,
  deleteFromWishlist: PropTypes.any,
  addToCart: PropTypes.any,
}
