import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import Layout from "components/layout/Layout"
import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"

import {
  getCategories,
  getProducts,
  getProductsByCategory,
} from "store/actions"
import ShopFilterBar from "./shop-filter-bar"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import InfiniteScroll from "react-infinite-scroll-component"

const ProductsList = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { products, loading, currentStore, defaultStore } = useSelector(
    state => ({
      products: state.Products.products,
      loading: state.Products.loading,
      currentStore: state.NearbyStore.store,
      defaultStore: state.SiteSettings.defaultStore,
    })
  )

  const [allProducts, setAllProducts] = useState([])
  const [customLoading, setCustomLoading] = useState(false)

  const query = location?.query

  console.log("query", query)

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  //pagination
  const limit = 20
  const allpages = products?.total / limit

  let [pages, setPages] = useState(1)

  console.log(pages)
  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }

  //end of pagination

  const [sideBarShow, setSideBarShow] = useState(false)

  useEffect(() => {
    if (sideBarShow === true) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "scroll"
    }
  }, [sideBarShow])

  //api

  useEffect(() => {
    dispatch(getCategories(1, "all"))
    dispatch(getCategories(1, "sub"))
    dispatch(getCategories(1, "grouped-main"))
  }, [dispatch])

  useEffect(() => {
    const options = {
      searchText: query?.search,
      page: pages,
      custom: "all",
      sort: "",
      limit: limit,
      store: currentStoreId,
    }

    const CatOptions = {
      ...options,
      custom: query?.cat?.replace(/-/g, " "),
    }

    if (query?.isProduct === false && query?.cat) {
      dispatch(getProductsByCategory(CatOptions))
    } else {
      dispatch(getProducts(options))
    }
  }, [dispatch, query, pages, currentStoreId])

  const product = products?.products

  useEffect(() => {
    setPages(1)
  }, [query?.cat])

  useEffect(() => {
    if (pages === 1) {
      setAllProducts([])
      setAllProducts([...product])
    } else {
      setAllProducts([...allProducts, ...product])
    }
  }, [products, pages])

  const filteredProductByStore = allProducts?.filter(
    prod => prod.store === currentStoreId
  )

  const ids = filteredProductByStore?.map(o => o._id)

  const filteredFinalProd = filteredProductByStore.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )
  const isSearch = query?.isSearch !== undefined && query?.isSearch === true

  console.log(isSearch)

  const handleSreachProd = () => {
    if (isSearch) {
      return product
    } else {
      return filteredFinalProd
    }
  }

  useEffect(() => {
    if (loading === true) {
      setCustomLoading(true)
    }
    if (loading === false) {
      setTimeout(() => {
        setCustomLoading(false)
      }, 1500)
    }
  }, [loading])

  return (
    <>
      <Layout noBreadcrumb="d-none" footer={false}>
        <section className="mt-0 mb-50">
          <div className="container">
            <div className="row flex-row-reverse">
              <ShopFilterBar
                sideBarShow={sideBarShow}
                setSideBarShow={setSideBarShow}
              />
              <div className="col-12">
                <InfiniteScroll
                  scrollThreshold={0.6}
                  dataLength={handleSreachProd()?.length}
                  next={HandlefetchNextPage}
                  hasMore={true}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  loader={customLoading && <ProductListLoader key={0} />}
                >
                  <div className="row product-grid-3">
                    {handleSreachProd()?.length <= 0 ? (
                      <h3>No Products Found </h3>
                    ) : (
                      <>
                        {handleSreachProd()?.map((item, i) => (
                          <div
                            className="col-lg-1-5 col-md-3 col-6 col-sm-4 "
                            key={i}
                          >
                            <ProductCardOne product={item} />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </InfiniteScroll>
              </div>

              {/* {sideBarShow && <div className="background-cover "></div>} */}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  products: state.products,
})

const mapDidpatchToProps = {
  // openCart,
  //   fetchProduct,
  // fetchMoreProduct,
}

export default connect(mapStateToProps, mapDidpatchToProps)(ProductsList)

ProductsList.propTypes = {
  products: PropTypes.any,
  fetchProduct: PropTypes.any,
}
