import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import React from "react"
import PropTypes from "prop-types"
import { useHistory, useLocation, useParams } from "react-router-dom"
import {
  getCategories,
  getProducts,
  getProductsByCategory,
  getSingleCollection,
} from "store/actions"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import CustomFilterBar from "./custom-filter-bar"
import InfiniteScroll from "react-infinite-scroll-component"

const ProductsList = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const history = useHistory()

  const { allProducts, loading, currentStore, singleCollection, defaultStore } =
    useSelector(state => ({
      allProducts: state.Products.products,
      loading: state.Products.loading,
      currentStore: state.NearbyStore.store,
      singleCollection: state.LayoutGroup.singleCollection,
      defaultStore: state.SiteSettings.defaultStore,
    }))

  const [fullProducts, setFullProducts] = useState([])

  const query = location?.query

  const catName = query?.cat?.replace(/-/g, " ")

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  console.log(singleCollection?.products)

  //pagination
  const limit = 20
  const allpages = allProducts?.total / limit
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }
  //end of pagination

  const [sideBarShow, setSideBarShow] = useState(false)

  useEffect(() => {
    if (sideBarShow === true) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "scroll"
    }
  }, [sideBarShow])

  //api

  useEffect(() => {
    dispatch(getCategories(1, "all"))
    dispatch(getCategories(1, "sub"))
    dispatch(getCategories(1, "grouped-main"))
  }, [dispatch])

  console.log(query)

  useEffect(() => {
    const options = {
      searchText: query?.search,
      page: pages,
      custom: "all",
      sort: "",
      limit: query?.limit,
      store: currentStoreId,
    }

    const CatOptions = {
      ...options,
      custom: catName,
    }

    if (query?.isProduct === false && catName && catName !== "all") {
      dispatch(getProductsByCategory(CatOptions))
    } else if (query?.isProduct === false && query?.coll) {
      dispatch(getSingleCollection(query?.coll?.replace(/-/g, " ")))
    } else {
      dispatch(getProducts(options))
    }
  }, [dispatch, query, pages, currentStoreId])

  useEffect(() => {
    setPages(1)
  }, [query])

  useEffect(() => {
    const removeSpace = params?.name?.replace(/ /g, "-")

    if (params?.type === "collections" || params?.type === "collection") {
      history.push({
        pathname: location?.pathname,
        query: {
          ...location.query,
          cat: "",
          prod: "",
          coll: removeSpace,
          isProduct: false,
        },
      })
    } else {
      history.push({
        pathname: location?.pathname,
        query: {
          ...location.query,
          cat: removeSpace,
          prod: "",
          coll: "",
          isProduct: false,
        },
      })
    }
  }, [params?.name])

  // useEffect(() => {
  //   if (query?.cat) {
  //     history.push({
  //       pathname: `/custom/category/${query?.cat?.replace(/ /g, "-")}`,
  //       query: {
  //         ...location.query,
  //       },
  //     })
  //   }
  // }, [])

  const handleProductList = () => {
    if (query?.isProduct === false && query?.coll) {
      return singleCollection?.products
    } else {
      return allProducts?.products
    }
  }

  useEffect(() => {
    if (pages === 1) {
      if (handleProductList()) {
        setFullProducts([...handleProductList()])
      }
    } else {
      if (handleProductList()) {
        setFullProducts([...fullProducts, ...handleProductList()])
      }
    }
  }, [allProducts, pages])

  const filteredProductByStore = fullProducts?.filter(
    prod => prod.store === currentStoreId
  )

  const ids = filteredProductByStore?.map(o => o._id)

  const filteredFinalProd = filteredProductByStore.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )

  return (
    <>
      {/* <Breadcrumb2 title={params?.type} noTags /> */}
      <section className="mt-0 mb-30">
        <div className="container">
          <div className="row flex-row-reverse">
            <CustomFilterBar
              sideBarShow={sideBarShow}
              setSideBarShow={setSideBarShow}
            />
            <div className="col-12 p-4">
              <InfiniteScroll
                scrollThreshold={0.6}
                dataLength={filteredFinalProd?.length}
                next={HandlefetchNextPage}
                hasMore={true}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                loader={loading && <ProductListLoader key={0} />}
              >
                <div className="row product-grid-3">
                  {handleProductList()?.length === 0 ? (
                    <h3>No Products Found </h3>
                  ) : (
                    <>
                      {filteredFinalProd?.map((item, i) => (
                        <div
                          className="col-lg-1-5 col-md-3 col-6 col-sm-4"
                          key={i}
                        >
                          <ProductCardOne product={item} />
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                  <nav aria-label="Page navigation example">
                    <Pagination
                      getPaginationGroup={getPaginationGroup}
                      currentPage={currentPage}
                      pages={pages}
                      next={next}
                      prev={prev}
                      handleActive={handleActive}
                    />
                  </nav>
                </div> */}
                </div>
              </InfiniteScroll>
            </div>

            {/* {sideBarShow && <div className="background-cover "></div>} */}
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  products: state.products,
  productFilters: state.productFilters,
})

const mapDidpatchToProps = {
  // openCart,
  //   fetchProduct,
  // fetchMoreProduct,
}

export default connect(mapStateToProps, mapDidpatchToProps)(ProductsList)

ProductsList.propTypes = {
  products: PropTypes.any,
  productFilters: PropTypes.any,
  fetchProduct: PropTypes.any,
}

//images
