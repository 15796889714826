import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import usePrice from "constants/UsePrice"
import useCloudinary from "constants/UseCloudinaryImage"

function SearchListVertical({ product, setsHowList }) {
  const { currencyCode, oldPrice, price, currentVarient, hideProduct } =
    usePrice(product)

  const removeSpace = product?.primaryLang?.name?.replace(/ /g, "-")

  return (
    <>
      {hideProduct === true ? (
        ""
      ) : (
        <div
          className=" mb-sm-5 mb-md-0 mt-3 wow animate__animated animate__fadeInUp"
          data-wow-delay="0"
        >
          <div className="product-list-small  animated animated">
            <article
              className="d-flex align-items-center hover-up"
              onClick={() => setsHowList(false)}
            >
              <figure className="mb-0 mx-3">
                <Link to={`/products/${removeSpace}`}>
                  <img
                    src={useCloudinary(
                      product.images[0]?.url,
                      "c_thumb,h_200,w_200"
                    )}
                    className="h:80px w:80px"
                    alt=""
                  />
                </Link>
              </figure>
              <div className="col-md-8 mb-0">
                <h6>
                  <Link to={`/products/${removeSpace}`}>
                    {product?.primaryLang?.name} ({currentVarient})
                  </Link>
                </h6>
                <div className="product-price">
                  <span>
                    {currencyCode} {price}{" "}
                  </span>
                  <span className="old-price">{oldPrice}</span>
                </div>
              </div>
            </article>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchListVertical
SearchListVertical.propTypes = {
  product: PropTypes.any,
  setsHowList: PropTypes.func,
}
