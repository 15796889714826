import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

import store from "./store"
import AppMobile from "App_mobile"

const width = window?.innerWidth
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
const mobileApp = (
  <BrowserRouter>
    <AppMobile />
  </BrowserRouter>
)

ReactDOM.render(width >= 520 ? app : mobileApp, document.getElementById("root"))
serviceWorker.register()
