import React, { useCallback, useRef, useState } from "react"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import PropTypes from "prop-types"
import mapStyle from "./mapStyle"

import { IoMdLocate } from "react-icons/io"
import { useDispatch } from "react-redux"
import { getUserLocation } from "store/actions"
import { MapSearch } from "./MapSearch"
import { FaSpinner } from "react-icons/fa"

const libraries = ["places"]

const mapContainerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "0 0 15px 15px",
}
const center = {
  lat: 11.041630014282479,
  lng: 76.08493600000001,
}

const options = {
  styles: mapStyle,
  disableDefaltUI: true,
  zoomConrol: true,
  maxZoom: 20,
}
// AIzaSyDCtMQTTL0-VzJ4hZWaDgRo1pnQBR_Bo38
export default function MyGoogleMap({ MAP_PUBLIC_KEY }) {
  const dispatch = useDispatch()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MAP_PUBLIC_KEY,
    libraries,
  })

  const [markers, setMarkers] = useState([])
  const [currentMapLoading, setCurrentMapLoading] = useState(false)

  const mapRef = useRef()
  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])

  const zoomTo = ({ lat, lng }) => {
    if (lat && lng) {
      mapRef.current?.panTo({ lat, lng })
      mapRef.current?.setZoom(14)
    }
  }

  if (loadError) return "Error loading map"
  if (!isLoaded)
    return (
      <div className="d-flex align-items-center justify-center">
        <FaSpinner className="mx-2 spinner" /> map is loading
      </div>
    )

  const handleMapMarker = e => {
    setMarkers(current => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ])
    dispatch(getUserLocation([e.latLng.lat(), e.latLng.lng()]))
    localStorage.setItem("pre_location", [e.latLng.lat(), e.latLng.lng()])
  }

  const handleGetCurrentLocation = () => {
    setCurrentMapLoading(true)

    navigator.geolocation.getCurrentPosition(
      position => {
        zoomTo({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
        setMarkers(current => [
          {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            time: new Date(),
          },
        ])
        setCurrentMapLoading(false)
      },
      () => null
    )
  }

  return (
    <div className="position-relative custom-border">
      <MapSearch zoomTo={zoomTo} setMarkers={setMarkers} />
      <button
        title="get current location"
        className="locate_map"
        onClick={() => handleGetCurrentLocation()}
      >
        {currentMapLoading ? (
          <FaSpinner className="mx-2 spinner" />
        ) : (
          <IoMdLocate />
        )}
      </button>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        options={options}
        onClick={e => handleMapMarker(e)}
        onLoad={onMapLoad}
      >
        {markers?.map(marker => (
          <Marker
            key={marker.time.toISOString()}
            position={{ lat: marker.lat, lng: marker.lng }}
          />
        ))}
      </GoogleMap>
    </div>
  )
}

MyGoogleMap.propTypes = {
  MAP_PUBLIC_KEY: PropTypes.string,
}
